<template>
  <div>
    <div class="desktop-vloot-table">
      <div
        class="row"
        style="position: fixed;
               z-index: 4999;
              width: 90%;"
      >
        <div class="col-md-2 col-lg-2 col-sm-0 col-xs-0"></div>
        <div class="col-md-8 col-lg-8 col-sm-0 col-xs-0 search-block-gebruiker">
          <div class="row">
            <div class="col-md-5 col-lg-5 col-sm-0 col-xs-0">
              <div class="locatie-seprator-div q-pr-sm q-pl-sm">
                <q-select
                  v-model="locatieUser"
                  :options="accountuser"
                  label="Locatie"
                  dense
                  borderless
                  options-dense
                  class="select-locatieUser"
                  @input="getUsersData()"
                />
              </div>
            </div>
            <!-- <div class="col-md-3 col-lg-3 col-sm-0 col-xs-0">
              <div class="actief-user-seprator-div q-pr-sm q-pl-sm">
                <q-select
                  v-model="actiefUser"
                  :options="actiefUserOptions"
                  label="Actief"
                  dense
                  borderless
                  options-dense
                  class="select-actief-user"
                />
              </div>
            </div> -->
            <div class="col-md-7 col-lg-7 col-sm-0 col-xs-0">
              <div class="zoek-usr-input-div q-pl-sm">
                <q-input
                  v-model="userSearchQuery"
                  dense
                  borderless
                  placeholder="Zoek gebruiker…"
                  class="zoek-input-gebruiker"
                >
                </q-input>
                <div class="search-icon-div">
                  <q-icon name="search" size="21px" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-lg-2 col-sm-0 col-xs-0"></div>
      </div>
      <div class="alle-button-div">
        <div class="alle-gebruikers-text">
          Alle gebruikers
        </div>
        <div class="gebruiker-button-div">
          <q-btn
            class="gebruiker-button text-white"
            label="Gebruiker toevoegen"
            @click="GebruikerPopupClick()"
          />
        </div>
      </div>

      <q-dialog v-model="GebruikerPopup">
        <q-card style="width: 700px; max-width: 80vw;">
          <q-card-section class="row items-center q-pb-none">
            <div class="gebruiker text-h6">Gebruiker toevoegen</div>
            <q-space />
            <q-btn icon="close" flat round dense v-close-popup />
          </q-card-section>

          <q-card-section>
            <div class="row voornam">
              <div class="col-md-3 col-lg-3 col-sm-0 col-xs-0">
                <div class="gebruiker-text">
                  Naam
                </div>
              </div>
              <div class="col-md-9 col-lg-9 col-sm-0 col-xs-0">
                <div>
                  <q-input
                    rounded
                    outlined
                    v-model="voornaam"
                    placeholder="Naam gebruiker…"
                    dense
                    :disable="!editUserEmail"
                  />
                </div>
              </div>
            </div>
            <!-- <div class="row" style="padding: 20px;">
              <div class="col-md-3 col-lg-3 col-sm-0 col-xs-0">
                <div class="gebruiker-text">
                  Achternaam
                </div>
              </div>
              <div class="col-md-9 col-lg-9 col-sm-0 col-xs-0">
                <div>
                  <q-input
                    rounded
                    outlined
                    v-model="achternaam"
                    placeholder="Achternaam gebruiker…"
                    dense
                  />
                </div>
              </div>
            </div> -->
            <div class="row" style="padding: 20px;">
              <div class="col-md-3 col-lg-3 col-sm-0 col-xs-0">
                <div class="gebruiker-text">
                  Email
                </div>
              </div>
              <div class="col-md-9 col-lg-9 col-sm-0 col-xs-0">
                <div>
                  <q-input
                    rounded
                    outlined
                    v-model="Email"
                    placeholder="Email gebruiker…"
                    dense
                    :disable="!editUserEmail"
                  />
                </div>
              </div>
            </div>
            <div class="row" style="padding: 20px;">
              <div class="col-md-3 col-lg-3 col-sm-0 col-xs-0">
                <div class="gebruiker-text">
                  Telefoon
                </div>
              </div>
              <div class="col-md-9 col-lg-9 col-sm-0 col-xs-0">
                <div>
                  <q-input
                    rounded
                    outlined
                    v-model="Telefoon"
                    placeholder="Telefoon gebruiker…"
                    dense
                    :disable="!editUserEmail"
                    type="number"
                  />
                </div>
              </div>
            </div>
            <div class="row" style="padding: 20px;">
              <div class="col-md-3 col-lg-3 col-sm-0 col-xs-0">
                <div class="gebruiker-text">
                  Locatie
                </div>
              </div>
              <div class="col-md-9 col-lg-9 col-sm-0 col-xs-0">
                <div>
                  <q-select
                    rounded
                    outlined
                    v-model="Locatie"
                    dense
                    options-dense
                    label="Locatie"
                    :options="createaccountuser"
                    v-if="editUserEmail"
                  />
                  <div v-else>{{ Locatie.label }}</div>
                </div>
              </div>
            </div>

            <div class="row" v-show="!editUserEmail" style="padding: 20px;">
              <div class="col-md-6 col-lg-6 col-sm-0 col-xs-0">
                <div class="wijzigen-block-text">
                  Status bij aanmaken gebruiker
                </div>
              </div>
              <div class="col-md-6 col-lg-6 col-sm-0 col-xs-0">
                <div class="in-validation">In behandeling</div>
              </div>
            </div>

            <div class="opslaan-button-div">
              <q-btn
                v-if="editUserEmail"
                class="opslaan-button text-white"
                label="Opslaan"
                @click="CreateUser"
              />
              <q-btn
                v-if="!editUserEmail"
                class="opslaan-button text-white"
                label="Close"
                @click="GebruikerPopup = false"
              />
            </div>
          </q-card-section>
        </q-card>
      </q-dialog>
      <div class="table-user-div">
        <q-table
          :data="resultUserQuery"
          :columns="columnsCont"
          :table-header-style="{ backgroundColor: '#a00020', color: 'white' }"
          :pagination.sync="pagination"
        >
          <template v-slot:body="props">
            <q-tr :props="props" class="cursor-pointer">
              <q-td key="Gebruiker" :props="props" >
                <!-- <div class="gebruiker-pic-name"> -->
                <!-- <div class="gebruiker-pic">
                    <q-avatar size="40px">
                      <img src="https://cdn.quasar.dev/img/avatar.png" />
                    </q-avatar>
                  </div> -->
                <div>
                  {{ props.row.Name }}
                </div>
                <!-- </div> -->
              </q-td>

              <q-td key="Locatie" :props="props" >
                <div>
                  {{ props.row.AccountName.split("-")[1] }}
                </div>
              </q-td>

              <q-td key="Email" :props="props" >
                {{ props.row.Email }}
              </q-td>

              <q-td key="Telefoonnummer" :props="props" >
                {{ props.row.Phone }}
              </q-td>

              <q-td key="PortaalRechten" :props="props" >
                <!-- <div class="status"> -->
                <q-avatar
                  size="8px"
                  color="green"
                  style="display: inline-block; margin:10px;margin-top: 7px;"
                  v-if="
                    props.row.Status &&
                      !!props.row.PoralAuthorizationName !== false
                  "
                />
                <q-avatar
                  size="8px"
                  color="red"
                  style="display: inline-block; margin:10px;margin-top: 7px;"
                  v-else-if="
                    !props.row.Status &&
                      !!props.row.PoralAuthorizationName !== false
                  "
                />
                <div style="display:inline-block;">
                  {{ props.row.PoralAuthorizationName }}
                </div>
                <!-- </div> -->
              </q-td>

              <q-td key="sender" :props="props" >
                <div>
                  <!-- <q-icon name="more_vert" color="grey" size="18px" /> -->
                  <q-btn icon="more_vert" color="grey" flat @click.stop="">
                    <q-menu>
                      <q-list style="min-width:190px;">
                        <q-item
                          clickable
                          v-close-popup
                          @click="CallEditPopup(props.row)"
                        >
                          <div>
                            <q-icon color="grey" name="edit" />
                          </div>
                          <div class="q-pl-xs">Edit</div>
                        </q-item>
                        <q-item
                          clickable
                          v-close-popup
                          @click="DeleteUser(props.row)"
                        >
                          <div>
                            <q-icon color="grey" name="delete_outline" />
                          </div>
                          <div class="q-pl-xs">Delete</div>
                        </q-item>
                      </q-list>
                    </q-menu>
                  </q-btn>
                </div>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
    </div>

    <!-- editPopup -->

    <q-dialog v-model="editPopup">
      <q-card style="width: 600px; max-width: 80vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Edit User</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>
        <q-card-section>
          <div class="row voornam">
            <div class="col-md-3 col-lg-3 col-sm-0 col-xs-0">
              <div class="gebruiker-text">
                Naam
              </div>
            </div>
            <div class="col-md-9 col-lg-9 col-sm-0 col-xs-0">
              <div>
                <q-input
                  rounded
                  outlined
                  v-model="EditableNaam"
                  placeholder="Naam gebruiker…"
                  dense
                  :disable="!editUserStatus"
                />
              </div>
            </div>
          </div>

          <div class="row" style="padding: 20px;">
            <div class="col-md-3 col-lg-3 col-sm-0 col-xs-0">
              <div class="gebruiker-text">
                Email
              </div>
            </div>
            <div class="col-md-9 col-lg-9 col-sm-0 col-xs-0">
              <div>
                <q-input
                  rounded
                  outlined
                  v-model="EditableEmail"
                  placeholder="Email gebruiker…"
                  dense
                  :disable="!editUserStatus"
                />
              </div>
            </div>
          </div>
          <div class="row" style="padding: 20px;">
            <div class="col-md-3 col-lg-3 col-sm-0 col-xs-0">
              <div class="gebruiker-text">
                Telefoon
              </div>
            </div>
            <div class="col-md-9 col-lg-9 col-sm-0 col-xs-0">
              <div>
                <q-input
                  rounded
                  outlined
                  v-model="EditableTelefoon"
                  placeholder="Telefoon gebruiker…"
                  dense
                  :disable="!editUserStatus"
                  type="number"
                />
              </div>
            </div>
          </div>
          <div class="row" style="padding: 20px;">
            <div class="col-md-3 col-lg-3 col-sm-0 col-xs-0">
              <div class="gebruiker-text">
                Locatie
              </div>
            </div>
            <div class="col-md-9 col-lg-9 col-sm-0 col-xs-0">
              <div>
                <q-select
                  rounded
                  outlined
                  v-model="EditableLocatie"
                  dense
                  options-dense
                  label="Leiden"
                  :options="accountuser"
                  v-if="editUserStatus"
                />
                <div v-else>
                  {{ EditableLocatie ? EditableLocatie.label : null }}
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-show="!editUserStatus" style="padding: 20px;">
            <div class="col-md-6 col-lg-6 col-sm-0 col-xs-0">
              <div class="wijzigen-block-text">
                Status van gebruiker bewerken
              </div>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-0 col-xs-0">
              <div class="in-validation">In behandeling</div>
            </div>
          </div>

          <div class="opslaan-button-div">
            <q-btn
              v-if="editUserStatus"
              class="opslaan-button text-white"
              label="Opslaan"
              @click="EditUser"
            />
            <q-btn
              v-if="!editUserStatus"
              class="opslaan-button text-white"
              label="Close"
              @click="editPopup = false"
            />
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="DeleteConfirmPopup" persistent>
      <q-card
        style="width: 22vw;
               height: 18vh;
               min-height:110px;
               margin-top: -10vh;"
      >
        <q-card-section class="row items-center">
          <span class="q-ml-sm"
            >Weet u zeker dat de gebruiker wilt verwijderen?</span
          >
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="#A01D1F" v-close-popup />
          <q-btn
            flat
            label="OK"
            color="red"
            @click="ConfirmDeleteUser()"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <div class="mobile-vloot-table">
      No mobile design for this page
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
export default {
  created() {
    this.getAccountUsers();
    this.getUsersData();
  },
  computed: {
    ...mapState({
      accountuser: "accountuser",
      userdata: "userdata",
      createaccountuser: "createaccountuser",
    }),
    resultUserQuery() {
      if (this.userSearchQuery) {
        return this.userdata.filter((item) => {
          return (
            this.userSearchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.Name.toLowerCase().includes(v)) ||
            this.userSearchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.AccountSF.toLowerCase().includes(v)) ||
            this.userSearchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.Email.toLowerCase().includes(v)) ||
            this.userSearchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.Phone.toLowerCase().includes(v))
          );
        });
      } else {
        return this.userdata;
      }
    },
  },
  data() {
    return {
      pagination: {
        rowsPerPage: 20,
      },
      EditableNaam: null,
      EditableEmail: null,
      EditableTelefoon: null,
      EditableLocatie: {
        label: null,
        value: null,
      },
      idContactPersons: null,
      PortalUserSettings: null,
      DeleteConfirmPopup: false,
      editPopup: false,
      editUserEmail: true,
      editUserStatus: true,
      userSearchQuery: null,
      locatieUser: null,
      actiefUser: null,
      Locatie: null,
      zoekGebruiker: "",
      GebruikerPopup: false,
      locatieUserOptions: ["Google", "Facebook", "Twitter", "Apple", "Oracle"],
      actiefUserOptions: ["Google", "Facebook", "Twitter", "Apple", "Oracle"],
      locatieOptions: ["Google", "Facebook", "Twitter", "Apple", "Oracle"],
      voornaam: "",
      achternaam: "",
      Email: "",
      Telefoon: "",
      columnsCont: [
        {
          name: "Gebruiker",
          align: "left",
          label: "GEBRUIKER",
          field: "Gebruiker",
          sortable: true,
          style: "width:15%",
        },
        {
          name: "Locatie",
          align: "center",
          label: "LOCATIE",
          field: "Locatie",
          sortable: true,
          style: "width:15%",
        },
        {
          name: "Email",
          align: "left",
          label: "EMAIL",
          field: "Email",
          sortable: true,
          style: "width:15%",
        },
        // {
        //   name: "Telefoonnummer",
        //   align: "center",
        //   label: "TELEFOONNUMMER",
        //   field: "Telefoonnummer",
        //   sortable: true,
        //   style: "max-width: 10px",
        // },
        {
          name: "PortaalRechten",
          align: "left",
          label: "PORTAALRECHTEN",
          field: "PortaalRechten",
          sortable: true,
          style: "width:15%",
        },
        {
          name: "sender",
          align: "right",
          field: "sender",
          style: "width:15%",
        },
      ],
      dataCont: [
        {
          Gebruiker: "Evert van Roekel",
          Locatie: "Leiden",
          Email: "evert@nimble.expert",
          Telefoonnummer: "+31612345678",
          Status: "Actief",
        },
        {
          Gebruiker: "Evert van Roekel",
          Locatie: "Leiden",
          Email: "evert@nimble.expert",
          Telefoonnummer: "+31612345678",
          Status: "Actief",
        },
        {
          Gebruiker: "Evert van Roekel",
          Locatie: "Leiden",
          Email: "evert@nimble.expert",
          Telefoonnummer: "+31612345678",
          Status: "Actief",
        },
        {
          Gebruiker: "Evert van Roekel",
          Locatie: "Leiden",
          Email: "evert@nimble.expert",
          Telefoonnummer: "+31612345678",
          Status: "Actief",
        },
        {
          Gebruiker: "Evert van Roekel",
          Locatie: "Leiden",
          Email: "evert@nimble.expert",
          Telefoonnummer: "+31612345678",
          Status: "Actief",
        },
        {
          Gebruiker: "Evert van Roekel",
          Locatie: "Leiden",
          Email: "evert@nimble.expert",
          Telefoonnummer: "+31612345678",
          Status: "Actief",
        },
        {
          Gebruiker: "Evert van Roekel",
          Locatie: "Leiden",
          Email: "evert@nimble.expert",
          Telefoonnummer: "+31612345678",
          Status: "Actief",
        },
        {
          Gebruiker: "Evert van Roekel",
          Locatie: "Leiden",
          Email: "evert@nimble.expert",
          Telefoonnummer: "+31612345678",
          Status: "Actief",
        },
        {
          Gebruiker: "Evert van Roekel",
          Locatie: "Leiden",
          Email: "evert@nimble.expert",
          Telefoonnummer: "+31612345678",
          Status: "Actief",
        },
        {
          Gebruiker: "Evert van Roekel",
          Locatie: "Leiden",
          Email: "evert@nimble.expert",
          Telefoonnummer: "+31612345678",
          Status: "Actief",
        },
      ],
    };
  },
  methods: {
    getAccountUsers() {
      this.$store.dispatch("getAccountUsers", {
        this: this,
      });
    },
    GebruikerPopupClick() {
      this.GebruikerPopup = true;
      this.editUserEmail = true;
    },
    getUsersData() {
      localStorage.setItem(
        "locateiId",
        this.locatieUser ? this.locatieUser.value : null
      );

      this.$store.dispatch("getUsersData", {
        this: this,
        locatieUser: this.locatieUser ? this.locatieUser.value : null,
      });
    },

    CreateUser() {
      this.GebruikerPopup = true;
      this.editUserEmail = false;

      let idAccount = null;
      let idContactPersons = null;
      let portalUserSettings = null;
      let selectedAccountsData = this.Locatie.value.split(",");

      console.log("users", selectedAccountsData);

      if (selectedAccountsData !== null) {
        idAccount = selectedAccountsData[0];
        portalUserSettings = selectedAccountsData[1];
        idContactPersons = selectedAccountsData[2];
      }

      this.$store.dispatch("postCreateUser", {
        this: this,
        idContactPersons: idContactPersons,
        portalUserSettings: portalUserSettings,
        idAccount: idAccount,
        name: this.voornaam ? this.voornaam : null,
        email: this.Email ? this.Email : null,
        phone: this.Telefoon ? this.Telefoon : null,
      });
    },

    CallEditPopup(userEditData) {
      this.editPopup = true;
      this.editUserStatus = true;
      console.log("userEditData", userEditData);
      this.EditableNaam = userEditData.Name;
      this.EditableTelefoon = userEditData.Phone;
      this.EditableEmail = userEditData.Email;
      this.idContactPersons = userEditData.idContactPersons;
      this.EditableLocatie.label = userEditData.AccountName;
      this.EditableLocatie.value = userEditData.idAccount;
      this.PortalUserSettings = userEditData.portalUserSettings;
      console.log(this.EditableLocatie);
    },
    EditUser() {
      console.log(this.EditableLocatie);
      this.editPopup = true;
      this.editUserStatus = false;
      console.log(this.editUserStatus);

      this.$store.dispatch("editUser", {
        this: this,
        idContactPersons: this.idContactPersons,
        idAccountSF: this.EditableLocatie ? this.EditableLocatie.value : null,
        Name: this.EditableNaam,
        Email: this.EditableEmail,
        Phone: this.EditableTelefoon,
        PortalUserSettings: this.PortalUserSettings,
      });
    },

    DeleteUser(deleteRowData) {
      this.idContactPersons = deleteRowData.idContactPersons;
      this.DeleteConfirmPopup = true;
    },
    ConfirmDeleteUser() {
      this.DeleteConfirmPopup = false;
      this.$store.dispatch("deleteUser", {
        this: this,
        idContactPersons: this.idContactPersons,
      });
    },
  },
};
</script>
<style lang="scss">
.search-block-gebruiker {
  margin-top: -30px;
  z-index: 2000;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #00000029;
  border-radius: 4px 0px 0px 4px;
  opacity: 1;
  height: 50px;
  position: sticky;
}
.locatie-seprator-div {
  border-right: 1px solid #898989;
}
.select-locatieUser {
  width: 100%;
}
.actief-user-seprator-div {
  border-right: 1px solid #898989;
}
.select-actief-user {
  width: 100%;
}
.zoek-usr-input-div {
  display: flex;
}
.zoek-input-gebruiker {
  width: 100%;
}
.search-icon-div {
  background: #a00020;
  color: white;
  padding: 0px;
  width: 45px;
  margin-top: 0px;
  margin-left: 6px;
  text-align: center;
  cursor: pointer;
  padding-top: 10px;
  margin-bottom: 0px;
}
.alle-gebruikers-text {
  padding-left: 18px;
  font: normal normal bold 25px/66px Arial;
  letter-spacing: 1.5px;
  color: #000000;
  text-transform: uppercase;
}
.alle-button-div {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}
.gebruiker-button-div {
  padding-top: 17px;
  padding-right: 18px;
}
.gebruiker-button {
  font-family: "Montserrat", sans-serif;
  background-color: #a00020 !important;
}
.gebruiker-button:hover {
  background-color: black !important;
}
.table-user-div {
  padding: 10px;
}
.gebruiker {
  letter-spacing: 1px;
  color: #333434;
  text-transform: uppercase;
}
.voornam {
  padding: 50px 20px 20px 20px;
}
.gebruiker-text {
  font: normal normal bold 17px/20px Arial;
  letter-spacing: 0.85px;
  color: #333434;
  padding-top: 6px;
}
.opslaan-button-div {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 20px;
}
.opslaan-button {
  font-family: "Montserrat", sans-serif;
  background-color: #a00020 !important;
  width: 160px !important;
}
.opslaan-button:hover {
  background-color: black !important;
}
.gebruiker-pic-name {
  display: flex;
  justify-content: space-around;
}
.status {
  display: flex;
  justify-content: space-around;
}
.wijzigen-block-text {
  font: normal normal bold 17px/20px Arial;
  letter-spacing: 0.85px;
  color: #333434;
}
.in-validation {
  font: normal normal bold 17px/20px Arial;
  letter-spacing: 0.85px;
  color: #a00020;
}

@media all and (max-width: 1023px) {
  .mobile-vloot-table {
    display: block;
  }
  .desktop-vloot-table {
    display: none;
  }
}
@media all and (min-width: 1024px) {
  .mobile-vloot-table {
    display: none;
  }
  .desktop-vloot-table {
    display: block;
    width: 90%;
    margin: auto !important;
  }
}

// media query structure
// @media all and (min-width: 1651px){
//   .select-locatieUser{
//     min-width: 314px;
//   }
//   .select-actief-user{
//     min-width: 314px;
//   }
//   .zoek-input-gebruiker{
//     width: 588px !important;
//     padding-top: 5px;
//     padding-left: 15px;
//   }
// }

// @media all and (max-width: 1650px) and (min-width:1551px){
// }
// @media all and (max-width: 1550px) and (min-width:1371px){
//   .select-locatieUser{
//     min-width: 207px;
//   }
//   .select-actief-user{
//     min-width: 207px;
//   }
//   .zoek-input-gebruiker{
//     width: 482px !important;
//     padding-top: 5px;
//     padding-left: 15px;
//   }
// }
// @media all and (max-width: 1370px) and (min-width:1301px){
//   .select-locatieUser{
//     min-width: 207px;
//   }
//   .select-actief-user{
//     min-width: 207px;
//   }
//   .zoek-input-gebruiker{
//     width: 482px !important;
//     padding-top: 5px;
//     padding-left: 15px;
//   }
// }
// @media all and (max-width: 1300px) and (min-width: 1201px){
//   .select-locatieUser{
//     min-width: 207px;
//   }
//   .select-actief-user{
//     min-width: 207px;
//   }
//   .zoek-input-gebruiker{
//     width: 482px !important;
//     padding-top: 5px;
//     padding-left: 15px;
//   }
// }
// @media all and (max-width: 1200px) and (min-width: 901px){
//   .select-locatieUser{
//     min-width: 165px;
//   }
//   .select-actief-user{
//     min-width: 165px;
//   }
//   .zoek-input-gebruiker{
//     width: 588px !important;
//     padding-top: 5px;
//     padding-left: 15px;
//   }
//   .search-icon-div{
//     background: #a00020;
//     color: white;
//     padding: 0px;
//     width: 75px;
//     margin-top: 0px;
//     margin-left: 6px;
//     text-align: center;
//     cursor: pointer;
//     padding-top: 15px;
//     margin-bottom: -5px;
//   }
// }

// @media all and (max-width: 900px) and (min-width: 501px) {
// }
// @media all and (max-width: 500px) and (min-width: 100px) {
// }
</style>
