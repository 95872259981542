import { render, staticRenderFns } from "./ContractenTable.vue?vue&type=template&id=28d68bd0&scoped=true&"
import script from "./ContractenTable.vue?vue&type=script&lang=js&"
export * from "./ContractenTable.vue?vue&type=script&lang=js&"
import style0 from "./ContractenTable.vue?vue&type=style&index=0&id=28d68bd0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28d68bd0",
  null
  
)

export default component.exports
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSelect,QInput,QIcon,QTable,QTr,QTd,QAvatar,QCard,QBtn,QScrollArea});
