<template>
  <div>
    <!-- Web devlopment -->
    <div class="desktop-devlopmemt">
      <div
        class="row"
        style="position: fixed;
         z-index: 4999;
        width: 90%;"
      >
        <div class="col-md-2 col-lg-2 col-sm-0 col-xs-0"></div>
        <div class="col-md-8 col-lg-8 col-sm-0 col-xs-0 search-block-cont">
          <div class="row">
            <div class="col-md-5 col-lg-5 col-sm-0 col-xs-0">
              <div class="object-seprator-div q-pr-sm q-pl-sm">
                <q-select
                  v-model="objectCnt"
                  :options="accountuser"
                  label="Object"
                  dense
                  borderless
                  options-dense
                  class="select-object"
                  @input="getContractData()"
                />
              </div>
            </div>
            <!-- <div class="col-md-3 col-lg-3 col-sm-0 col-xs-0">
              <div class="actief-seprator-div q-pr-sm q-pl-sm">
                <q-select
                  v-model="actief"
                  :options="actiefOptions"
                  label="Actief"
                  dense
                  borderless
                  options-dense
                  class="select-actief"
                />
              </div>
            </div> -->
            <div class="col-md-7 col-lg-7 col-sm-0 col-xs-0">
              <div class="zoek-input-div q-pl-sm">
                <q-input
                  v-model="contractSearchQuery"
                  dense
                  borderless
                  placeholder="Zoek machine…."
                  class="zoek-input"
                >
                </q-input>
                <div class="search-icon-div">
                  <q-icon name="search" size="21px" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-lg-2 col-sm-0 col-xs-0"></div>
      </div>
      <div class="mijnc-text">
        MIJN CONTRACTEN
      </div>
      <div class="table-cont-div">
        <q-table
          :data="resultContractQuery"
          :columns="columnsCont"
          :table-header-style="{ backgroundColor: '#a00020', color: 'white' }"
        >
          <template v-slot:body="props">
            <q-tr :props="props" class="cursor-pointer">
              <!-- @click="rowDetails(props.row)" -->
              <!-- <q-td key="statusColoum" :props="props" auto-width>
                <q-avatar size="8px" color="green" />
              </q-td> -->

              <q-td key="Contractnummer" :props="props" auto-width>
                <q-avatar
                  size="8px"
                  color="red"
                  style="display: inline-block; margin:10px;margin-top: 7px;"
                  v-if="props.row.Status"
                />
                <q-avatar
                  size="8px"
                  color="green"
                  style="display: inline-block; margin:10px;margin-top: 7px;"
                  v-else
                />
                <div style="display: inline-block;">
                  {{ props.row.ContractNumber }}
                </div>
              </q-td>

              <q-td key="Objectnumber" :props="props" auto-width>
                <div>
                  {{ props.row.ObjectModel }}
                </div>
              </q-td>

              <!-- <q-td key="Serienummer" :props="props" auto-width>
                {{ props.row.Serienummer }}
              </q-td> -->

              <q-td key="typeContract" :props="props" auto-width>
                {{ props.row.ContractType }}
              </q-td>

              <q-td key="UrenPerJaar" :props="props" auto-width>
                {{ props.row.ContractHours }}
              </q-td>

              <q-td key="Startdatum" :props="props" auto-width>
                {{ props.row.ContractStartDate }}
              </q-td>

              <q-td key="Einddatum" :props="props" auto-width>
                {{ props.row.ContractEndDate }}
              </q-td>

              <!-- <q-td key="sender" :props="props" auto-width>
                <div>
                  <q-icon name="more_vert" color="grey" size="18px" />
                </div>
              </q-td> -->
            </q-tr>
          </template>
        </q-table>
      </div>
    </div>

    <!-- Mobile devlopment -->
    <div class="mobile-devlopment">
      <div class="row">
        <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
        <div class="col-md-0 col-lg-0 col-sm-10 col-xs-10">
          <div class="q-pt-lg q-pb-lg">
            <q-card>
              <q-input
                v-model="contractSearchQuery"
                placeholder="Zoek contract…"
                class="zoekInputContract"
                borderless
              >
                <template v-slot:append>
                  <q-btn
                    flat
                    icon="search"
                    class="search-icone-contract text-white"
                  />
                </template>
              </q-input>
            </q-card>
          </div>
        </div>
        <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
      </div>
      <div class="row">
        <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
        <div class="col-md-0 col-lg-0 col-sm-10 col-xs-10">
          <div
            style="display: flex;
           justify-content: space-between;
           align-items: center;"
          >
            <div class="mb-mijn-contracten-heading q-py-md">
              Mijn Contracten
            </div>
            <div>
              <q-btn
                icon-right="subdirectory_arrow_left"
                label="Ga terug"
                flat
                no-caps
                color="grey-8"
                @click="goHomePage"
              />
            </div>
          </div>

          <q-scroll-area
            :thumb-style="thumbStyleMB"
            :bar-style="barStyleMB"
            style="height: 500px; max-width: 100%;"
            class="mb-cont-scroll"
          >
            <div
              class="mb-cont-notificstion q-py-md"
              v-for="(dataContContracten, index) in resultContractQuery"
              :key="index"
            >
              <q-card
                class="mb-notification-card q-pa-sm"
                :class="
                  dataContContracten.Status
                    ? 'mb-red-color-notification'
                    : 'mb-green-color-notification'
                "
              >
                <div class="mb-card-details">
                  <div>
                    <div class="mb-contracten-heading q-py-xs">
                      <div class="contracten-number">
                        {{ dataContContracten.ContractNumber }}
                      </div>
                      <div class="contracten-location q-pl-sm">
                        {{ dataContContracten.AccountName }}
                      </div>
                      <div class="contracten-popye-icone">
                        <q-icon name="arrow_right" size="25px" />
                      </div>
                      <div class="contracten-object">
                        {{ dataContContracten.Objectnumber }}
                      </div>
                    </div>
                    <div class="mb-cont-end-date ">
                      <div class="mb-type-of-contracten q-py-sm">
                        {{ dataContContracten.ContractType }}
                      </div>
                      <div class="mb-contracten-end-date q-py-sm">
                        Einde contract: {{ dataContContracten.ContractEndDate }}
                      </div>
                    </div>
                  </div>

                  <div class="mb-card-icone">
                    <q-icon name="more_vert" size="30px" color="grey" />
                  </div>
                </div>
              </q-card>
            </div>
          </q-scroll-area>
        </div>
        <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
      </div>
      <div class="ge-trug-cont-div">
        <!-- <q-btn
          icon-right="subdirectory_arrow_left"
          label="Ga terug"
          flat
          no-caps
          color="grey-8"
          @click="goHomePage"
        /> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
export default {
  created() {
    this.getAccountUsers();
    this.getContractData();
  },

  computed: {
    ...mapState({
      accountuser: "accountuser",
      contractdata: "contractdata",
    }),
    resultContractQuery() {
      if (this.contractSearchQuery) {
        return this.contractdata.filter((item) => {
          return (
            this.contractSearchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.ContractNumber.toLowerCase().includes(v)) ||
            this.contractSearchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.ContractType.toLowerCase().includes(v)) ||
            this.contractSearchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.ContractHours.toLowerCase().includes(v))
          );
        });
      } else {
        return this.contractdata;
      }
    },
  },

  data() {
    return {
      contractSearchQuery: null,
      objectCnt: null,
      actief: null,
      zoekSearch: "",
      objectOptions: ["Google", "Facebook", "Twitter", "Apple", "Oracle"],
      actiefOptions: ["Google", "Facebook", "Twitter", "Apple", "Oracle"],
      columnsCont: [
        // {
        //   name: "statusColoum",
        //   align: "right",
        // },
        {
          name: "Contractnummer",
          align: "left",
          label: "CONTRACTNUMMER",
          field: "Contractnummer",
          sortable: true,
        },
        {
          name: "Objectnumber",
          align: "center",
          label: "UW REFERENTIE (SERIENUMMER)",
          field: "Objectnumber",
          sortable: true,
        },
        // {
        //   name: "Serienummer",
        //   align: "center",
        //   label: "SERIENUMMER",
        //   field: "Serienummer",
        //   sortable: true,
        // },
        {
          name: "typeContract",
          align: "center",
          label: "TYPE CONTRACT",
          field: "typeContract",
          sortable: true,
        },
        {
          name: "UrenPerJaar",
          align: "center",
          label: "UREN PER JAAR",
          field: "UrenPerJaar",
          sortable: true,
        },
        {
          name: "Startdatum",
          align: "center",
          label: "STARTDATUM",
          field: "Startdatum",
          sortable: true,
        },
        {
          name: "Einddatum",
          align: "center",
          label: "EINDDATUM",
          field: "Einddatum",
          sortable: true,
        },
        // {
        //   name: "sender",
        //   align: "center",
        //   style: "max-width: 10px",
        // },
      ],
      dataCont: [
        {
          key: 1,
          Contractnummer: "C045598",
          Objectnumber: "Popeye (KJA-Z001)",
          Serienummer: 543112233,
          typeContract: "Na-calculatie",
          Startdatum: "01-01-2021",
          Einddatum: "31-12-2021",
          colorID: 1,
        },
        {
          key: 2,
          Contractnummer: "C045598",
          Objectnumber: "Popeye (KJA-Z001)",
          Serienummer: 543112233,
          typeContract: "Na-calculatie",
          Startdatum: "01-01-2021",
          Einddatum: "31-12-2021",
          colorID: 2,
        },
        {
          key: 3,
          Contractnummer: "C045598",
          Objectnumber: "Popeye (KJA-Z001)",
          Serienummer: 543112233,
          typeContract: "Na-calculatie",
          Startdatum: "01-01-2021",
          Einddatum: "31-12-2021",
          colorID: 2,
        },
        {
          key: 4,
          Contractnummer: "C045598",
          Objectnumber: "Popeye (KJA-Z001)",
          Serienummer: 543112233,
          typeContract: "Na-calculatie",
          Startdatum: "01-01-2021",
          Einddatum: "31-12-2021",
          colorID: 1,
        },
        {
          key: 5,
          Contractnummer: "C045598",
          Objectnumber: "Popeye (KJA-Z001)",
          Serienummer: 543112233,
          typeContract: "Na-calculatie",
          Startdatum: "01-01-2021",
          Einddatum: "31-12-2021",
          colorID: 1,
        },
        {
          key: 6,
          Contractnummer: "C045598",
          Objectnumber: "Popeye (KJA-Z001)",
          Serienummer: 543112233,
          typeContract: "Na-calculatie",
          Startdatum: "01-01-2021",
          Einddatum: "31-12-2021",
          colorID: 2,
        },
        {
          key: 7,
          Contractnummer: "C045598",
          Objectnumber: "Popeye (KJA-Z001)",
          Serienummer: 543112233,
          typeContract: "Na-calculatie",
          Startdatum: "01-01-2021",
          Einddatum: "31-12-2021",
          colorID: 2,
        },
        {
          key: 8,
          Contractnummer: "C045598",
          Objectnumber: "Popeye (KJA-Z001)",
          Serienummer: 543112233,
          typeContract: "Na-calculatie",
          Startdatum: "01-01-2021",
          Einddatum: "31-12-2021",
          colorID: 1,
        },
        {
          key: 9,
          Contractnummer: "C045598",
          Objectnumber: "Popeye (KJA-Z001)",
          Serienummer: 543112233,
          typeContract: "Na-calculatie",
          Startdatum: "01-01-2021",
          Einddatum: "31-12-2021",
          colorID: 2,
        },
        {
          key: 10,
          Contractnummer: "C045598",
          Objectnumber: "Popeye (KJA-Z001)",
          Serienummer: 543112233,
          typeContract: "Na-calculatie",
          Startdatum: "01-01-2021",
          Einddatum: "31-12-2021",
          colorID: 1,
        },
      ],
      thumbStyleMB: {
        right: "-18px",
        borderRadius: "5px",
        backgroundColor: "#a00020",
        width: "5px",
        opacity: 0.75,
      },
      barStyleMB: {
        right: "-20px",
        borderRadius: "9px",
        backgroundColor: "#a00020",
        width: "8px",
        opacity: 0.2,
      },
    };
  },
  methods: {
    rowDetails(data) {
      console.log("SELECTED_ROW", data);
      this.$router.push({ name: "ContractenDesc", query: { key: data.key } });
    },
    goHomePage() {
      this.$router.push({ name: "Home" });
    },
    getAccountUsers() {
      this.$store.dispatch("getAccountUsers", {
        this: this,
      });
    },
    getContractData() {
      localStorage.setItem(
        "locateiId",
        this.objectCnt ? this.objectCnt.value : null
      );
      console.log("objectCnt", this.objectCnt);
      this.$store.dispatch("getContractData", {
        this: this,
        objectCnt: this.objectCnt ? this.objectCnt.value : null,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.search-block-cont {
  margin-top: -30px;
  z-index: 2000;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #00000029;
  border-radius: 4px 0px 0px 4px;
  opacity: 1;
  height: 50px;
  position: sticky;
}
.object-seprator-div {
  border-right: 1px solid #898989;
}
.select-object {
  width: 100%;
}
.actief-seprator-div {
  border-right: 1px solid #898989;
}
.select-actief {
  width: 100%;
}
.actief-seprator {
  height: 50px !important;
}
.zoek-input-div {
  display: flex;
}
.zoek-input {
  width: 100%;
}
.search-icon-div {
  background: #a00020;
  color: white;
  padding: 0px;
  width: 45px;
  margin-top: 0px;
  margin-left: 6px;
  text-align: center;
  cursor: pointer;
  padding-top: 10px;
  margin-bottom: 0px;
}
.mijnc-text {
  padding-left: 18px;
  font: normal normal bold 25px/66px Arial;
  letter-spacing: 1.5px;
  color: #000000;
  text-transform: uppercase;
  padding-top: 15px;
}
.table-cont-div {
  padding: 10px;
}
//mobile devlopmemt
.zoekInputContract {
  padding-left: 10px;
}
.search-icone-contract {
  background: #a00020;
  padding-top: 10px;
  padding-bottom: 10px;
}
.ge-trug-cont-div {
  width: 100%;
  text-align: center;
}
.mb-mijn-contracten-heading {
  font: normal normal bold 18px/25px Arial;
  letter-spacing: 1px;
  color: #333434;
  text-transform: uppercase;
}
.mb-notification-card {
  min-height: 60px;
}
.mb-card-details {
  display: flex;
  justify-content: space-between;
}
.mb-card-icone {
  transform: translate(0, 30%);
}
.mb-red-color-notification {
  border-left: 15px solid #a00020;
}
.mb-green-color-notification {
  border-left: 15px solid #40d000;
}
.mb-contracten-heading {
  display: flex;
}
.contracten-number {
  font: normal normal bold 11px/17px Arial;
  color: #a00020;
}
.contracten-location {
  font: normal normal bold 11px/17px Arial;
  color: gray;
  text-transform: uppercase;
}
.contracten-object {
  font: normal normal bold 11px/17px Arial;
  color: #000000;
}
.contracten-popye-icone {
  margin-top: -4px;
}
.mb-type-of-contracten {
  font: normal normal bold 11px/5px Arial;
  color: #333434;
}
.mb-contracten-end-date {
  font: normal normal bold 11px/5px Arial;
  color: #333434;
}
.mb-cont-scroll {
  height: 500px;
  max-width: 100%;
}

@media all and (max-width: 1023px) {
  .mobile-devlopment {
    display: block;
  }
  .desktop-devlopmemt {
    display: none;
  }
}
@media all and (min-width: 1024px) {
  .mobile-devlopment {
    display: none;
  }
  .desktop-devlopmemt {
    display: block;
    width: 90%;
    margin: auto !important;
  }
}
</style>
