<template>
  <div>
    <!-- Desktop view -->
    <div class="desktop-view">
      <div
        class="row"
        style="position: fixed;
         z-index: 4999;
        width: 90%;"
      >
        <div class="col-md-2 col-lg-2 col-sm-0 col-xs-0"></div>
        <div class="col-md-8 col-lg-8 col-sm-0 col-xs-0 search-block-obj">
          <div class="row">
            <div class="col-md-5 col-lg-5 col-sm-0 col-xs-0">
              <div class="locatie-seprator-div q-pr-sm q-pl-sm">
                <q-select
                  v-model="Locatie"
                  :options="accountuser"
                  label="Locatie"
                  dense
                  borderless
                  options-dense
                  class="locatie-object"
                  @input="getActivityData()"
                />
              </div>
            </div>
            <!-- <div class="col-md-3 col-lg-3 col-sm-0 col-xs-0">
              <div class="open-seprator-div q-pr-sm q-pl-sm">
                <q-select
                  v-model="Open"
                  :options="OpenfOptions"
                  label="Open"
                  dense
                  borderless
                  options-dense
                  class="open-actief"
                />
              </div>
            </div> -->
            <div class="col-md-7 col-lg-7 col-sm-0 col-xs-0">
              <div class="zoek-input-div q-pl-sm">
                <q-input
                  v-model="activitySearchQuery"
                  dense
                  borderless
                  placeholder="Zoek melding…"
                  class="zoek-input"
                >
                </q-input>
                <div class="search-icon-div">
                  <q-icon name="search" size="21px" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-lg-2 col-sm-0 col-xs-0"></div>
      </div>
      <div class="object-text q-pt-md q-pl-md">
        MIJN ACTIVITEITEN
      </div>
      <!-- style="height: calc(100% - 82px); max-width: 100%; " -->
      <q-scroll-area
        :thumb-style="thumbStyle"
        :bar-style="barStyle"
        class="scroll-area-section"
      >
        <div
          class="notification-div"
          v-for="(objectNotifiation, index) in resultActivityQuery"
          :key="index"
        >
          <!-- <div class="date-div q-pb-sm">
            {{ objectNotifiation.objDate }}
          </div> -->
          <!-- :class="
              objectNotifiation.colorId == 1
                ? 'red-notification'
                : 'gray-notification'
            " -->
          <!-- q-py-md -->
          <!-- objectNotifiation.elementColor == 'black',
                  machine_flex_firstdivision_red:
                    objectNotifiation.elementColor == 'red', -->
          <q-card
            class="notification-card"
            @click="descriptionVisibility(objectNotifiation)"
          >
            <div class="machine-flex">
              <div class="machine-flex-first-box">
                <div
                  :class="{
                    machine_flex_firstdivision_black: true,
                  }"
                >
                  <div>
                    <h6>
                      {{ objectNotifiation.Day }} {{ objectNotifiation.Month }}
                    </h6>
                    <h5>{{ objectNotifiation.Year }}</h5>
                  </div>
                </div>
                <div class="q-pl-md">
                  <div class="machine-fullName">
                    {{ objectNotifiation.Title }}
                  </div>
                  <div class="machine-desc q-pt-xs">
                    {{ objectNotifiation.Description }}
                  </div>
                </div>
              </div>
              <div class="machine-name q-pr-md">
                <div class="notification-button">
                  <div style="font-size: 1rem;">
                    {{ objectNotifiation.PortalActivityTypeTag }}
                  </div>
                </div>
                <!-- <div
                  v-else-if="objectNotifiation.tagType === 'Object'"
                  class="notification-button"
                >
                  Object
                </div>
                <div v-else class="notification-button"></div> -->
                <!-- <q-btn
                  outline
                  label="Service"
                  v-if="objectNotifiation.tagType === 'Service'"
                  class="notification-button"
                />
                <q-btn
                  outline
                  style="color:#333434; width: 148px; cursor: default;"
                  label="Object"
                  v-else-if="objectNotifiation.tagType === 'Object'"
                /> -->
                <!-- v-model="objectNotifiation.objId"
                  v-if="objectNotifiation.elementColor == 'black'" -->
                <!-- <q-btn
                  outline
                  style="color:#a00020; width: 148px;"
                  label="OBJECT"
                  v-model="objectNotifiation.objId"
                  v-if="objectNotifiation.elementColor == 'red'"
                /> -->

                <!-- removed part -->
                <!-- <div class="GeneratedText">
                  <div style="padding-left: 20px;">
                    <div>{{ objectNotifiation.SerialNumber }}</div>
                    <div>{{ objectNotifiation.ObjectNumber }}</div>
                  </div>
                </div> -->

                <!-- <div style="display:flex;">

                  <div class="q-mr-md">
                    <div>Aanvrager</div>
                    <div class="q-pt-xs">{{ objectNotifiation.Aanvrager }}</div>
                  </div>

                  <div>
                    <div>Objectnummer</div>
                    <div class="q-pt-xs">
                      {{ objectNotifiation.objLocation }}
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </q-card>
          <div
            class="card-description"
            v-if="
              objectNotifiation.show &&
                (objectNotifiation.RequestUser !== null ||
                  objectNotifiation.ServiceType !== null ||
                  objectNotifiation.ServiceStatus !== null ||
                  objectNotifiation.ServicesDescription !== null)
            "
          >
            <div class="row card-description-row" style="text-align: start;">
              <div class="col-3 card-description-column">Aanvrager</div>
              <div class="col-9">{{ objectNotifiation.RequestUser }}</div>
            </div>
            <div class="row card-description-row">
              <div class="col-3 card-description-column">
                Type service
              </div>
              <div class="col-9">{{ objectNotifiation.ServiceType }}</div>
            </div>
            <div class="row card-description-row">
              <div class="col-3 card-description-column">Status</div>
              <div class="col-9">{{ objectNotifiation.ServiceStatus }}</div>
            </div>
            <div class="row card-description-row" style="font-weight:600">
              Omschrijving
            </div>
            <div class="row card-description-row">
              {{ objectNotifiation.ServicesDescription }}
            </div>
          </div>
        </div>
      </q-scroll-area>
    </div>
    <!-- Mobile view -->
    <div class="mobile-view">
      <div class="row">
        <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
        <div class="col-md-0 col-lg-0 col-sm-10 col-xs-10">
          <div class="q-pt-lg q-pb-lg">
            <q-card>
              <q-input placeholder="Zoek melding…" class="zoekInput" borderless>
                <template v-slot:append>
                  <q-btn flat icon="search" class="search-icone text-white" />
                </template>
              </q-input>
            </q-card>
          </div>
        </div>
        <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
      </div>
      <div class="row">
        <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
        <div class="col-md-0 col-lg-0 col-sm-10 col-xs-10">
          <div
            style="display: flex;
           justify-content: space-between;
           align-items: center;"
          >
            <div class="mb-meldingen-heading q-py-md">
              ACTIVITEIT
            </div>
            <div>
              <q-btn
                icon-right="subdirectory_arrow_left"
                label="Ga terug"
                flat
                no-caps
                color="grey-8"
                @click="goHomePage"
              />
            </div>
          </div>

          <q-scroll-area
            :thumb-style="thumbStyleMB"
            :bar-style="barStyleMB"
            class="scroll-meldingen"
          >
            <div
              class="mb-notification-div"
              v-for="(objectNotifiation, index) in resultActivityQuery"
              :key="index"
            >
              <div class="date-popye q-py-lg">
                <div class="mb-date">
                  {{ objectNotifiation.Day }} {{ objectNotifiation.Month }}
                  {{ objectNotifiation.Year }}
                </div>
                <div class="mb-Popeye">
                  <div
                    style="font-size: 13px;font-weight: 600;margin-bottom: 0.3rem;"
                  >
                    {{ objectNotifiation.SerialNumber }}
                  </div>
                  <div style="font-size: 16px;">
                    {{ objectNotifiation.ObjectNumber }}
                  </div>
                </div>
              </div>
              <!-- :class=" objectNotifiation.colorId == 1 ?
              'mb-color-notification-red' : 'mb-color-notification-green' " -->
              <q-card
                class="mb-notification-card q-pa-sm mb-color-notification-green"
                @click="descriptionVisibility(objectNotifiation)"
              >
                <div class="mb-machine-fullName q-py-xs">
                  {{ objectNotifiation.Title }}
                </div>
                <div class="mb-machine-desc q-py-xs">
                  {{ objectNotifiation.Description }}
                </div>
              </q-card>

              <div
                class="card-description"
                v-if="
                  objectNotifiation.show &&
                    (objectNotifiation.RequestUser !== null ||
                      objectNotifiation.ServiceType !== null ||
                      objectNotifiation.ServiceStatus !== null ||
                      objectNotifiation.ServicesDescription !== null)
                "
              >
                <div
                  class="row card-description-row"
                  style="text-align: start;"
                >
                  <div class="col-4 card-description-column">Aanvrager</div>
                  <div class="col-8">{{ objectNotifiation.RequestUser }}</div>
                </div>
                <div class="row card-description-row">
                  <div class="col-4 card-description-column">
                    Type service
                  </div>
                  <div class="col-8">
                    {{ objectNotifiation.ServiceType }}
                  </div>
                </div>
                <div class="row card-description-row">
                  <div class="col-4 card-description-column">Status</div>
                  <div class="col-8">
                    {{ objectNotifiation.ServiceStatus }}
                  </div>
                </div>
                <div class="row card-description-row" style="font-weight:600">
                  Omschrijving
                </div>
                <div class="row card-description-row">
                  {{ objectNotifiation.ServicesDescription }}
                </div>
              </div>
            </div>
          </q-scroll-area>
        </div>
        <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
      </div>
      <!-- <div class="ge-trug-div">
        <q-btn
          icon-right="subdirectory_arrow_left"
          label="Ga terug"
          flat
          no-caps
          color="grey-8"
          @click="goHomePage"
        />
      </div> -->
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
export default {
  created() {
    this.getAccountUsers();
    this.getActivityData();
  },
  computed: {
    ...mapState({
      accountuser: "accountuser",
      activitydata: "activitydata",
    }),
    resultActivityQuery() {
      // console.log("activityseatrch", this.activitySearchQuery);

      if (this.activitySearchQuery) {
        return this.activitydata.filter((item) => {
          return (
            this.activitySearchQuery
              .toLowerCase()
              .split(" ")
              .every((v) =>
                item.Year.toString()
                  .toLowerCase()
                  .includes(v)
              ) ||
            this.activitySearchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.Month?.toLowerCase().includes(v)) ||
            this.activitySearchQuery
              .toLowerCase()
              .split(" ")
              .every((v) =>
                item.Day.toString()
                  .toLowerCase()
                  .includes(v)
              ) ||
            this.activitySearchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.Title?.toLowerCase().includes(v)) ||
            this.activitySearchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.SerialNumber?.toLowerCase().includes(v)) ||
            this.activitySearchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.ObjectNumber?.toLowerCase().includes(v))
          );
        });
      } else {
        return this.activitydata;
      }
    },
  },

  data() {
    return {
      activitySearchQuery: null,
      card_description_el: false,
      date: "20-june-2021",
      Locatie: null,
      Open: null,
      zoekSearch: "",
      LocatieOptions: ["Google", "Facebook", "Twitter", "Apple", "Oracle"],
      OpenfOptions: ["Google", "Facebook", "Twitter", "Apple", "Oracle"],
      objectNotifiations: [
        {
          objId: 1,
          objDate: "20 JUNE 2021",
          objHeading: "Servicebeurt geannuleerd",
          objDesc:
            "De servicebeurt voor 26 juni 2021 is geannuleerd vanwege: storing is …",
          objLocation: "Liden",
          colorId: 1,
          Aanvrager: "Max Lagemann",
          show: false,
          elementColor: "black",
        },
        {
          objId: 2,
          objDate: "21 JUNE 2021",
          objHeading: "Servicebeurt geannuleerd",
          objDesc:
            "De servicebeurt voor 26 juni 2021 is geannuleerd vanwege: storing is …",
          objLocation: "Liden",
          colorId: 2,
          Aanvrager: "Sudhir Gupta",
          show: false,
          elementColor: "red",
        },
        {
          objId: 3,
          objDate: "22 JUNE 2021",
          objHeading: "Servicebeurt geannuleerd",
          objDesc: "De servicebeurt storing is …",
          objLocation: "Liden",
          colorId: 2,
          Aanvrager: "Sandeep pb",
          show: false,
          elementColor: "red",
        },
        {
          objId: 4,
          objDate: "23 JUNE 2021",
          objHeading: "Servicebeurt geannuleerd",
          objDesc: "De servicebeurt voor 26 jun",
          objLocation: "Liden",
          colorId: 1,
          Aanvrager: "Max Lagemann",
          show: false,
          elementColor: "red",
        },
        {
          objId: 5,
          objDate: "23 JUNE 2021",
          objHeading: "Servicebeurt geannuleerd",
          objDesc: "eannuleerd vanwege: storing is …",
          objLocation: "Liden",
          colorId: 2,
          Aanvrager: "Max Lagemann",
          show: false,
          elementColor: "red",
        },
        {
          objId: 6,
          objDate: "23 JUNE 2021",
          objHeading: "Servicebeurt geannuleerd",
          objDesc:
            "De servicebeurt voor 26 juni 2021 is geannuleerd vanwege: storing is …",
          objLocation: "Liden",
          colorId: 1,
          Aanvrager: "Max Lagemann",
          show: false,
          elementColor: "red",
        },
        {
          objId: 7,
          objDate: "23 JUNE 2021",
          objHeading: "Servicebeurt geannuleerd",
          objDesc: "De servicebeurt  vanwege: storing is …",
          objLocation: "Liden",
          colorId: 2,
          Aanvrager: "Max Lagemann",
          show: false,
          elementColor: "red",
        },
      ],
      thumbStyle: {
        right: "4px",
        borderRadius: "5px",
        backgroundColor: "#a00020",
        width: "5px",
        opacity: 0.75,
      },
      barStyle: {
        right: "2px",
        borderRadius: "9px",
        backgroundColor: "#a00020",
        width: "9px",
        opacity: 0.2,
        show: false,
        elementColor: "red",
      },
      thumbStyleMB: {
        right: "-18px",
        borderRadius: "5px",
        backgroundColor: "#a00020",
        width: "5px",
        opacity: 0.75,
        show: false,
        elementColor: "red",
      },
      barStyleMB: {
        right: "-20px",
        borderRadius: "9px",
        backgroundColor: "#a00020",
        width: "8px",
        opacity: 0.2,
        show: false,
        elementColor: "red",
      },
    };
  },
  methods: {
    goHomePage() {
      this.$router.push({ name: "Home" });
    },
    descriptionVisibility(objectNotifiation) {
      objectNotifiation.show = !objectNotifiation.show;
    },
    getAccountUsers() {
      this.$store.dispatch("getAccountUsers", {
        this: this,
      });
    },
    getActivityData() {
      console.log("Locatie", this.Locatie);

      localStorage.setItem(
        "locateiId",
        this.Locatie ? this.Locatie.value : null
      );

      this.$store.dispatch("getActivityData", {
        this: this,
        Locatie: this.Locatie ? this.Locatie.value : null,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.search-block-obj {
  margin-top: -30px;
  z-index: 2000;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #00000029;
  border-radius: 4px 0px 0px 4px;
  opacity: 1;
  height: 50px;
  position: sticky;
}
.locatie-seprator-div {
  border-right: 1px solid #898989;
}
.locatie-object {
  width: 100%;
}
.open-seprator-div {
  border-right: 1px solid #898989;
}
.open-actief {
  width: 100%;
}
.zoek-input-div {
  display: flex;
}
.zoek-input {
  width: 100%;
}
.search-icon-div {
  background: #a00020;
  color: white;
  padding: 0px;
  width: 45px;
  margin-top: 0px;
  margin-left: 6px;
  text-align: center;
  cursor: pointer;
  padding-top: 10px;
  margin-bottom: 0px;
}
.object-text {
  font: normal normal bold 25px/66px Arial;
  letter-spacing: 1.5px;
  color: #000000;
  text-transform: uppercase;
}
.notification-div {
  padding: 5px 25px 10px 25px;
}
.notification-card {
  box-shadow: 0px 0px 10px #00000029;
  height: 70px;
}
.date-div {
  font: normal normal normal 16px/18px Arial;
  letter-spacing: 0.8px;
  color: #333434;
  text-transform: uppercase;
}
.red-notification {
  border-left: 20px solid #a00020;
}
.gray-notification {
  border-left: 20px solid #333434;
}
.machine-flex {
  display: flex;
  height: 100%;
  // justify-content: space-between !important;
  align-items: center;
  justify-content: space-between;
  // position: relative;
}
.machine-flex-first-box {
  display: flex;
  align-items: center;
}
.machine-fullName {
  font: normal normal bold 18px/21px Arial;
  letter-spacing: 0.9px;
  color: #333434;
}
.machine-desc {
  font: normal normal normal 17px/20px Arial;
  letter-spacing: 0.85px;
  color: #333434;
}
.machine-name {
  font: normal normal normal 17px/20px Arial;
  letter-spacing: 0.85px;
  // position: absolute;
  // left: 73%;
  // display: flex;
  // gap: 50;
  // margin-left: 10%;
}
.GeneratedText {
  margin-left: 20px;
  border-left: outset;
}
.scroll-area-section {
  height: calc(100vh - 300px);
  max-width: 100%;
}

//mobile-view
.ge-trug-div {
  // position: absolute;
  // bottom: 0px;
  width: 100%;
  text-align: center;
}
.zoekInput {
  padding-left: 10px;
}
.search-icone {
  background: #a00020;
  padding-top: 10px;
  padding-bottom: 10px;
}
.mb-meldingen-heading {
  font: normal normal bold 18px/25px Arial;
  letter-spacing: 1px;
  color: #333434;
  text-transform: uppercase;
}
.date-popye {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
}
.mb-date {
  font: normal normal normal 16px/18px Arial;
  letter-spacing: 0.8px;
  color: #333434;
  text-transform: uppercase;
}
.mb-Popeye {
  font: normal normal normal 16px/18px Arial;
  letter-spacing: 0.8px;
  color: #333434;
}
.mb-notification-card {
  min-height: 60px;
}
.mb-color-notification-red {
  border-left: 15px solid #a00020;
}
.mb-color-notification-green {
  border-left: 15px solid #333434;
}
.mb-machine-fullName {
  font: normal normal bold 18px/21px Arial;
  color: #333434;
}
.mb-machine-desc {
  font: normal normal 14px/21px Arial;
  letter-spacing: 0.85px;
  color: #333434;
}
.scroll-meldingen {
  height: 500px;
  max-width: 100%;
}

.machine_flex_firstdivision_black {
  background-color: #333434;
  width: 90px;
  height: 100%;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.machine_flex_firstdivision_red {
  background-color: #cd282a;
  width: 6%;
  height: 100%;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-button {
  // color: #333434;

  // cursor: default;
  border: 2px solid rgba(71, 72, 72, 0.5);
  text-align: center;
  padding: 0.4em;
  width: 8em;
  min-height: 35px;
}

.card-description {
  /* height: 175px; */
  background-color: #eeeff3;
  padding: 20px;
}
.card-description-row {
  padding-bottom: 6px;
}
.card-description-column {
  font-weight: 600;
}

@media all and (max-width: 1023px) {
  .mobile-view {
    display: block;
  }
  .desktop-view {
    display: none;
  }
}
@media all and (min-width: 1024px) {
  .mobile-view {
    display: none;
  }
  .desktop-view {
    display: block;
    width: 90%;
    // height: 100%;
    margin: auto !important;
  }
}
</style>
