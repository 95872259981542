import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
Vue.use(Vuex)


// const apiBizagi = 'https://projects.nimble.expert/LiftingMotracAPI/'
// const apiBizagi = 'https://projects.nimble.expert/LiftingMotracACCAPI/'
// const apiBizagi = '/LiftingMotracAPI/' //dev
// const apiBizagi = '/LiftingMotracACCAPI/' //acc
const apiBizagi = 'https://motracapi.azurewebsites.net/'  //Prod API

// const apiURL = 'https://projects.nimble.expert' //dev
const apiURL = 'https://motracapi.azurewebsites.net' //azure

// const dashboardLink = "http://localhost:8080/" //LOCAL
// const dashboardLink = "https://projects.nimble.expert/motrac_serviceportal_dev/" //new DEV
//const dashboardLink = "https://projects.nimble.expert/motrac_serviceportal_acc/" //Acc
const dashboardLink = "https://mijn.motrac.nl/" //azure

const nTime = 6000;


export default new Vuex.Store({
  state: {
    // headerTab: sessionStorage.getItem("routeTab")
    //   ? sessionStorage.getItem("routeTab")
    //   : "Vloot",
    token: "",
    microsoftUrl: "",
    user: "",
    ServiceMobilePopup: false,
    cautionPopup: false,
    isLoading: false,
    accountuser: [],
    createaccountuser: [],
    vlootdata: [],
    contractdata: [],
    userdata: [],
    specificobjectnotificationdata: [],
    activitydata: [],
    objectdropdowndata: [],
    servicetypedata: [],
  },
  mutations: {
    SET_SERVICE_MOBILE_POPUP(state, ServiceMobilePopup) {
      state.ServiceMobilePopup = ServiceMobilePopup;
    },
    SET_CUATION_POPUP(state, cautionPopup) {
      state.cautionPopup = cautionPopup;
    },
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
    },
    SET_MICROSOFT_URL(state, microsoftUrl) {
      state.microsoftUrl = microsoftUrl;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_ACCOUNTUSER(state, data) {
      state.accountuser = data;
    },
    SET_CREATEACCOUNTUSER(state, data) {
      state.createaccountuser = data;
    },
    SET_VLOOTDATA(state, data) {
      state.vlootdata = data;
    },
    SET_CONTRACTDATA(state, data) {
      state.contractdata = data;
    },
    SET_USERDATA(state, data) {
      state.userdata = data;
    },
    SET_SPECIFICOBJECTNOTIFICATION(state, ObjectNotificationData) {
      state.specificobjectnotificationdata = ObjectNotificationData;
    },
    SET_ACTIVITYDATA(state, data) {
      state.activitydata = data;
    },
    SET_OBJECTDROPDOWNDATA(state, data) {
      state.objectdropdowndata = data;
    },
    SET_SERVICETYPE(state, data) {
      state.servicetypedata = data;
    },
  },
  actions: {
    getLoginUrls({ commit, dispatch }, This) {
      commit("SET_LOADING", true)
      axios.get(apiBizagi + 'api/Account/ExternalLogins?returnUrl=' + dashboardLink + '&generateState=true')
        .then((data) => {
          console.log("LOGIN_URLS", data)
          commit("SET_MICROSOFT_URL", data.data[1].Url);
          commit("SET_LOADING", false)
        }).catch((err) => {
          console.log("ERROR", err);
          commit("SET_LOADING", false);
        });
    },

    loginWithMicrosoft({ commit, state, dispatch }, This) {
      console.log("microsoftUrl", state.microsoftUrl)
      localStorage.setItem("microsoftUrl", state.microsoftUrl)
      window.location.href = apiURL + state.microsoftUrl;
      console.log("URI", window.location.hash)
      // dispatch("getUserInfo", {this:this})
    },

    getUser({ commit, state, dispatch }, This) {
      commit("SET_LOADING", true);
      axios.defaults.headers.common["Authorization"] = "bearer " + sessionStorage.getItem("token");
      axios.get(apiBizagi + "api/Account/UserInfo")
        .then(({ data }) => {

          console.log("USER_DETAILS", data);
          commit("SET_USER", data);
          commit("SET_LOADING", false);

        }).catch(error => {
          console.log('Error', error.message);
          console.log("ERROR", error.response.status);
          commit("SET_LOADING", false);
          if (error.response.status == 401) {
            This.this.$router.push({ name: "Login" });
          }
          else if (error.response.status == 404) {
            This.this.$notify({
              group: 'foo',
              type: 'warn',
              text: 'Verificatie mislukt, de gebruiker heeft geen toegang tot het dashboard.',
              duration: nTime,
            });
            This.this.$router.push({ name: "Login" });
          }
          else {
            This.this.$notify({
              group: 'foo',
              type: 'error',
              text: error.message,
              duration: nTime,
            });
            // This.this.$router.push({ name: "Login" });
          }
        });
    },

    getAccountUsers({ commit, state, dispatch }, This) {
      commit("SET_LOADING", true);
      axios.defaults.headers.common["Authorization"] = "bearer " + sessionStorage.getItem("token");
      axios.get(apiBizagi + "api/Account/GetAccountsOfUsers")
        .then(({ data }) => {

          const userData = data.map(obj => {
            obj.idAccount = obj.idAccount;
            obj.AccountName = obj.AccountName;
            return {
              value: obj.idAccount,
              label: obj.AccountName
            }
          })

          const createUserDropdownLocatie = data.map(obj => {
            const value = `${obj.idAccount},${obj.idPortalUserSettings},${obj.idContactPersons}`
            return {
              value: value,
              label: obj.AccountName
            }
          })

          console.log("AccountuserData", userData);
          console.log("createUserDropdownLocatie", createUserDropdownLocatie);
          commit("SET_ACCOUNTUSER", userData);
          commit("SET_CREATEACCOUNTUSER", createUserDropdownLocatie);

          commit("SET_LOADING", false);

        }).catch(error => {
          console.log('Error', error.message);
          console.log("ERROR", error.response.status);
          commit("SET_LOADING", false);
          if (error.response.status == 401) {
            This.this.$router.push({ name: "login" });
          } else {
            This.this.$notify({
              group: 'foo',
              type: 'error',
              text: error.message,
              duration: nTime,
            });
          }
        });
    },
    getVlootData({ commit, state, dispatch }, This) {
      commit("SET_LOADING", true);
      const DutchMonths = ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'];

      console.log('locate', This.Locatie);

      axios.defaults.headers.common["Authorization"] = "bearer " + sessionStorage.getItem("token");
      axios.get(apiBizagi + "api/ObjectContract/OverviewOfObject?Accountid=" + This.Locatie).then(({ data }) => {

        data = data.map(obj => {
          obj.Request_EndDateObject = obj.Request_EndDateObject ? obj.Request_EndDateObject.substr(0, obj.Request_EndDateObject.indexOf(' ')) : null;
          obj.BMWTEndDate = obj.BMWTEndDate ? obj.BMWTEndDate.substr(0, obj.BMWTEndDate.indexOf('T')) : null;
          const BmwtEndDate = new Date(obj.BMWTEndDate);
          const CurrentDate = new Date();

          let Month = null;
          let Year = null;

          if (BmwtEndDate != null) {
            const monthIndex = BmwtEndDate.getUTCMonth();
            Month = DutchMonths[monthIndex];
            Year = BmwtEndDate.getUTCFullYear();
          }

          obj.MachineHoursDate = obj.MachineHoursDate ? obj.MachineHoursDate.substr(0, obj.MachineHoursDate.indexOf('T')) : null;
          const MachineHoursDate = new Date(obj.MachineHoursDate);
          let machineDate = null;
          if (MachineHoursDate !== null) {
            machineDate = `${MachineHoursDate.getDate()}-${MachineHoursDate.getUTCMonth() + 1}-${MachineHoursDate.getUTCFullYear()}`;
          }

          const uwReference = obj.ExternalReference ? `${obj.SerialNumber} (${obj.ExternalReference})` : obj.SerialNumber;

          // console.log(BmwtEndDate);
          obj.ExternalReference = obj.ExternalReference;
          obj.SerialNumber = obj.SerialNumber;
          obj.BMWTStatus = obj.BMWTStatus;
          obj.MachineHours = obj.MachineHours;
          obj.MachineHoursDate = obj.MachineHoursDate;
          obj.MachineHoursDate = obj.MachineHoursDate;
          obj.idObjects = obj.idObjects;
          obj.PortalCount = obj.PortalCount;

          return {
            uwReference: uwReference,
            Status: CurrentDate > BmwtEndDate,
            BMWTEndDate: obj.BMWTEndDate,
            ExternalReference: obj.ExternalReference,
            SerialNumber: obj.SerialNumber,
            // SerialNumber: obj.ContractEndDate.substr(0, obj.ContractEndDate.indexOf('T'))
            BMWTStatus: obj.BMWTStatus,
            MachineHours: obj.MachineHours,
            MachineHoursDate: machineDate,
            idObjects: obj.idObjects,
            ObjectModel: obj.ObjectModel,
            PortalCount: obj.PortalCount,
            AccountName: obj.AccountName,
            idContactPerson: obj.idContactPerson,
            idContracts: obj.idContracts,
            editHours: true,
            nieuweUrenstand: "",
            editObjNummer: true,
            objNummer: "",
            editRegion: true,
            redenInput: "",
            Year: Year,
            Month: Month,
            Request_EndDateObject: obj.Request_EndDateObject,

          }



        })

        data = data.filter(function (obj) {
          return obj.ExternalReference !== null && obj.SerialNumber !== null;
        })
        console.log("VlootData", data);
        commit("SET_VLOOTDATA", data);
        commit("SET_LOADING", false);

      }).catch(error => {
        console.log('Error', error.message);
        console.log("ERROR", error.response.status);
        commit("SET_LOADING", false);
        if (error.response.status == 401) {
          This.this.$router.push({ name: "login" });
        } else {
          This.this.$notify({
            group: 'foo',
            type: 'error',
            text: error.message,
            duration: nTime,
          });
        }
      });
    },

    getContractData({ commit, state, dispatch }, This) {
      commit("SET_LOADING", true);

      console.log('raj locate', This.objectCnt);

      axios.defaults.headers.common["Authorization"] = "bearer " + sessionStorage.getItem("token");
      axios.get(apiBizagi + "api/Contracts/OverviewOfContract?Accountid=" + This.objectCnt).then(({ data }) => {

        data = data.map(obj => {

          obj.ContractEndDate = obj.ContractEndDate ? obj.ContractEndDate.substr(0, obj.ContractEndDate.indexOf('T')) : null;
          const ContractEndDate = new Date(obj.ContractEndDate);
          const CurrentDate = new Date();
          obj.ContractNumber = obj.ContractNumber;
          obj.ContractType = obj.ContractType;
          obj.ContractHours = obj.ContractHours;
          obj.ContractStartDate = obj.ContractStartDate ? obj.ContractStartDate.substr(0, obj.ContractStartDate.indexOf('T')) : null;
          obj.ExternalReference = obj.ExternalReference;
          obj.SerialNumber = obj.SerialNumber;

          return {
            Status: CurrentDate > ContractEndDate,
            ContractNumber: obj.ContractNumber,
            ContractType: obj.ContractType,
            ContractHours: obj.ContractHours,
            ContractStartDate: obj.ContractStartDate,
            ContractEndDate: obj.ContractEndDate,
            ObjectModel: `${obj.ExternalReference ? obj.ExternalReference : ""}(${obj.SerialNumber ? obj.SerialNumber : ""})`,
            AccountName: obj.AccountName,
          }

        })
        console.log("ContractData", data);
        commit("SET_CONTRACTDATA", data);
        commit("SET_LOADING", false);

      }).catch(error => {
        console.log('Error', error.message);
        console.log("ERROR", error.response.status);
        commit("SET_LOADING", false);
        if (error.response.status == 401) {
          This.this.$router.push({ name: "login" });
        } else {
          This.this.$notify({
            group: 'foo',
            type: 'error',
            text: error.message,
            duration: nTime,
          });
        }
      });
    },

    getActivityData({ commit, state, dispatch }, This) {
      commit("SET_LOADING", true);

      const DutchMonths = ['Jan', 'Feb', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];

      axios.defaults.headers.common["Authorization"] = "bearer " + sessionStorage.getItem("token");
      axios.get(apiBizagi + "api/AccountNotification/AccountPortalNotifications?idAccount=" + This.Locatie).then(({ data }) => {

        data = data.map(obj => {

          obj.NotificationDate = obj.NotificationDate ? obj.NotificationDate.substr(0, obj.NotificationDate.indexOf('T')) : null;
          const NotificationDate = new Date(obj.NotificationDate);


          let Month = null;
          let Year = null;
          let Day = null;

          if (NotificationDate != null) {
            const monthIndex = NotificationDate.getUTCMonth();
            Month = DutchMonths[monthIndex];
            Year = NotificationDate.getUTCFullYear();
            Day = NotificationDate.getUTCDate();
          }
          let tagType = null;
          if (obj.PortalActivityTypeName.startsWith("S"))
            tagType = "Service";
          else if (obj.PortalActivityTypeName.startsWith("O"))
            tagType = "Object";

          return {
            Day: Day,
            Month: Month,
            Year: Year,
            Title: obj.Title,
            Description: obj.Description,
            PortalActivityType: obj.PortalActivityTypeName,
            SerialNumber: obj.SerialNumber,
            ObjectNumber: obj.idObjects,
            RequestUser: obj.RequestUser,
            ServiceType: obj.ServiceTypeName,
            ServiceStatus: obj.ServiceStatusName,
            ServicesDescription: obj.ServicesDescription,
            show: false,
            tagType: tagType,
            PortalActivityTypeTag: obj.PortalActivityTypeTag,
          }
        })
        console.log("ActivityData", data);
        commit("SET_ACTIVITYDATA", data);
        commit("SET_LOADING", false);

      }).catch(error => {
        console.log('Error', error.message);
        console.log("ERROR", error.response.status);
        commit("SET_LOADING", false);
        if (error.response.status == 401) {
          This.this.$router.push({ name: "login" });
        } else {
          This.this.$notify({
            group: 'foo',
            type: 'error',
            text: error.message,
            duration: nTime,
          });
        }
      });
    },


    getUsersData({ commit, state, dispatch }, This) {
      commit("SET_LOADING", true);

      console.log('locatieUser', This.locatieUser);

      axios.defaults.headers.common["Authorization"] = "bearer " + sessionStorage.getItem("token");
      axios.get(apiBizagi + "api/Account/GetUserAccounts?AccountId=" + This.locatieUser).then(({ data }) => {

        data = data.map(obj => {

          obj.Name = obj.Name;
          obj.AccountSF = obj.AccountSF;
          obj.Email = obj.Email;
          obj.Phone = obj.Phone;


          return {

            Name: obj.Name,
            AccountSF: obj.AccountSF,
            Email: obj.Email,
            Phone: obj.Phone,
            AccountName: obj.AccountName,
            PoralAuthorizationName: obj.PoralAuthorizationName,
            Status: obj.PoralAuthorizationName === "Beheerder" || obj.PoralAuthorizationName === "Gebruiker" ? true : false,
            idContactPersons: obj.idContactPersons,
            idAccount: obj.idAccount,
            portalUserSettings: obj.idPortalUserSettings,
          }

        })
        console.log("UserData", data);
        commit("SET_USERDATA", data);
        commit("SET_LOADING", false);

      }).catch(error => {
        console.log('Error', error.message);
        console.log("ERROR", error.response.status);
        commit("SET_LOADING", false);
        if (error.response.status == 401) {
          This.this.$router.push({ name: "login" });
        } else {
          This.this.$notify({
            group: 'foo',
            type: 'error',
            text: error.message,
            duration: nTime,
          });
        }
      });
    },

    getObjectInfoData({ commit, state, dispatch }, This) {
      commit("SET_LOADING", true);

      const idObject = localStorage.getItem("idObject");

      axios.defaults.headers.common["Authorization"] = "bearer " + sessionStorage.getItem("token");
      axios.get(apiBizagi + "api/ObjectNotification/GetSpecificObjectsData?idObjects=" + idObject).then(({ data }) => {


        let machinedate = data.MachineHoursDate.substr(0, data.MachineHoursDate.indexOf('T'));


        const MachineHoursDate = new Date(machinedate);
        let machineDate = null;
        if (MachineHoursDate !== null) {
          machineDate = `${MachineHoursDate.getDate()}-${MachineHoursDate.getUTCMonth() + 1}-${MachineHoursDate.getUTCFullYear()}`;
        }

        let BMWTEndDate = data.BMWTEndDate ? data.BMWTEndDate.substr(0, data.BMWTEndDate.indexOf('T')) : null;

        const DutchMonths = ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'];


        let modifiedBMWTEndDate = null;
        // if (BMWTEndDate !== null) {
        //   let d = BMWTEndDate.split("-");
        //   console.log('modified date', d);
        //   modifiedBMWTEndDate = d[2] + '-' + d[1] + '-' + d[0];
        // }

        const BmwtEndDate = new Date(BMWTEndDate);


        let Month = null;
        let Year = null;

        if (BmwtEndDate != null) {
          const monthIndex = BmwtEndDate.getUTCMonth();
          Month = DutchMonths[monthIndex];
          Year = BmwtEndDate.getUTCFullYear();
          modifiedBMWTEndDate = `${Month} ${Year}`
        }


        This.this.dataObjInfo = [
          {
            id: 1,
            infoRowName: "Serienummer",
            infoRowDesc: data.SerialNumber,
          },
          {
            id: 2,
            infoRowName: "Uw referentie",
            infoRowDesc: data.ExternalReference,
          },
          {
            id: 3,
            infoRowName: "Model",
            infoRowDesc: data.ObjectModel,
          },

          {
            id: 5,
            infoRowName: "Tellerstand (datum)",
            // infoRowDesc: `${data.MachineHours} (${data.MachineHoursDate ? data.MachineHoursDate.substr(0, data.MachineHoursDate.indexOf('T')) : null})`,
            infoRowDesc: `${data.MachineHours} (${machineDate})`,
          },
          {
            id: 6,
            infoRowName: "BMWT-status",
            infoRowDesc: data.BMWTStatus,
          },
          {
            id: 7,
            infoRowName: "BMWT-vervaldatum",
            infoRowDesc: modifiedBMWTEndDate,
          },
        ]

        console.log("objectInfoData", data);
        console.log(This.this.dataObjInfo);
        // commit("SET_USERDATA", data);
        commit("SET_LOADING", false);

      }).catch(error => {
        console.log('Error', error.message);
        console.log("ERROR", error.response.status);
        commit("SET_LOADING", false);
        if (error.response.status == 401) {
          This.this.$router.push({ name: "login" });
        } else {
          This.this.$notify({
            group: 'foo',
            type: 'error',
            text: error.message,
            duration: nTime,
          });
        }
      });
    },
    getSpecificContractDetails({ commit, state, dispatch }, This) {
      commit("SET_LOADING", true);

      const idContracts = localStorage.getItem("idContracts");

      axios.defaults.headers.common["Authorization"] = "bearer " + sessionStorage.getItem("token");
      axios.get(apiBizagi + "api/ObjectNotification/GetSpecificContractsData?idContracts=" + idContracts).then(({ data }) => {

        let conStartDate = data.ContractStartDate ? data.ContractStartDate.substr(0, data.ContractStartDate.indexOf('T')) : null;

        let modifiedContractStartdate = null;
        if (conStartDate !== null) {
          let d = conStartDate.split("-");
          console.log('modified date', d);
          modifiedContractStartdate = d[2] + '-' + d[1] + '-' + d[0];
        }


        let conEndDate = data.ContractEndDate ? data.ContractEndDate.substr(0, data.ContractEndDate.indexOf('T')) : null;

        let modifiedContractEnddate = null;
        if (conEndDate !== null) {
          let d = conEndDate.split("-");
          console.log('modified date', d);
          modifiedContractEnddate = d[2] + '-' + d[1] + '-' + d[0];
        }



        This.this.dataContInfo = [
          {
            id: 1,
            contName: "Contractnummer",
            contDesc: data.ContractNumber,
          },
          {
            id: 2,
            contName: "Start datum",
            contDesc: modifiedContractStartdate,
          },
          {
            id: 3,
            contName: "Eind datum",
            contDesc: modifiedContractEnddate,
          },
          {
            id: 4,
            contName: "Uren per jaar",
            contDesc: data.ContractHours,
          },
          {
            id: 5,
            contName: "Contract type",
            contDesc: data.ContactGroupName,
          },
          {
            id: 6,
            contName: "Model",
            contDesc: data.ObjectModel,
          },
        ],

          console.log("cspecificcontractdata", data);


        commit("SET_LOADING", false);

      }).catch(error => {
        console.log('Error', error.message);
        console.log("ERROR", error.response.status);
        commit("SET_LOADING", false);
        if (error.response.status == 401) {
          This.this.$router.push({ name: "login" });
        } else {
          This.this.$notify({
            group: 'foo',
            type: 'error',
            text: error.message,
            duration: nTime,
          });
        }
      });
    },
    getSpecificObjectNotification({ commit, state, dispatch }, This) {
      commit("SET_LOADING", true);

      const idObject = localStorage.getItem("idObject");

      axios.defaults.headers.common["Authorization"] = "bearer " + sessionStorage.getItem("token");
      axios.get(apiBizagi + "api/ObjectNotification/SpecificObjectNotifications?idObjects=" + idObject).then(({ data }) => {
        console.log('specific object data', data);


        const DutchMonths = ['Jan', 'Feb', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];


        const ObjectNotificationData = data.map(obj => {

          let month = null;
          let day = null;
          let year = null;

          const notificationDate = obj.NotificationDate ? obj.NotificationDate.substr(0, obj.NotificationDate.indexOf('T')) : null;
          // var mydate = notificationDate.getUTCMonth() + 1;
          if (notificationDate != null) {
            const notification = new Date(notificationDate);
            const monthIndex = notification.getUTCMonth();
            month = DutchMonths[monthIndex];
            day = notification.getUTCDate();
            year = notification.getUTCFullYear();
          }



          return {
            Title: obj.Title,
            Description: obj.Description,
            DescriptiveElement: false,
            ServicesDescription: obj.ServicesDescription,
            ServiceStatusName: obj.ServiceStatusName,
            PortalActivityTypeName: obj.PortalActivityTypeName,
            Month: month,
            Day: day,
            Year: year,
            PortalActivityTypeTag: obj.PortalActivityTypeTag,
            RequestUser: obj.RequestUser,
            ServiceTypeName: obj.ServiceTypeName,
          }

        });
        console.log('notification data',)

        commit("SET_SPECIFICOBJECTNOTIFICATION", ObjectNotificationData);
        commit("SET_LOADING", false);
      }).catch(error => {
        console.log('Error', error.message);
        console.log("ERROR", error.response.status);
        commit("SET_LOADING", false);
        if (error.response.status == 401) {
          This.this.$router.push({ name: "login" });
        } else {
          This.this.$notify({
            group: 'foo',
            type: 'error',
            text: error.message,
            duration: nTime,
          });
        }
      });
    },

    postTellerStandHours({ commit, state, dispatch }, This) {
      commit("SET_LOADING", true);
      const sendData = {
        idContactPersons: This.idContactPerson,
        idContracts: This.idContracts,
        Request_MachineHours: This.newHour,
      }

      axios.defaults.headers.common["Authorization"] = "bearer " + sessionStorage.getItem("token");
      axios.post(apiBizagi + "api/PostObjectApi/Reportcounterreading", sendData).then(({ data }) => {
        console.log('tellenderHourStatus', data);
        commit("SET_LOADING", false);
      }).catch(error => {
        console.log('Error', error.message);
        console.log("ERROR", error.response.status);
        commit("SET_LOADING", false);
        if (error.response.status == 401) {
          This.this.$router.push({ name: "login" });
        } else {
          This.this.$notify({
            group: 'foo',
            type: 'error',
            text: error.message,
            duration: nTime,
          });
        }
      });
    },

    postObjectNumber({ commit, state, dispatch }, This) {
      commit("SET_LOADING", true);
      const sendData = {
        idContactPersons: This.idContactPerson,
        idContracts: This.idContracts,
        Request_ExternalReference: This.objectNumber,
      }

      axios.defaults.headers.common["Authorization"] = "bearer " + sessionStorage.getItem("token");
      axios.post(apiBizagi + "api/PostObjectApi/Changeyourreference", sendData).then(({ data }) => {
        console.log('objectNumberUpdateStatus', data);
        commit("SET_LOADING", false);
      }).catch(error => {
        console.log('Error', error.message);
        console.log("ERROR", error.response.status);
        commit("SET_LOADING", false);
        if (error.response.status == 401) {
          This.this.$router.push({ name: "login" });
        } else {
          This.this.$notify({
            group: 'foo',
            type: 'error',
            text: error.message,
            duration: nTime,
          });
        }
      });
    },

    postObjectInactive({ commit, state, dispatch }, This) {
      commit("SET_LOADING", true);
      const sendData = {
        idContactPersons: This.idContactPerson,
        idContracts: This.idContracts,
        Request_Reason: This.Request_Reason,
        Request_EndDateObject: This.objectEnddate
      }

      axios.defaults.headers.common["Authorization"] = "bearer " + sessionStorage.getItem("token");
      axios.post(apiBizagi + "api/PostObjectApi/Makeobjectinactive", sendData).then(({ data }) => {
        console.log('objectInactiveStatus', data);
        commit("SET_LOADING", false);
      }).catch(error => {
        console.log('Error', error.message);
        console.log("ERROR", error.response.status);
        commit("SET_LOADING", false);
        if (error.response.status == 401) {
          This.this.$router.push({ name: "login" });
        } else {
          This.this.$notify({
            group: 'foo',
            type: 'error',
            text: error.message,
            duration: nTime,
          });
        }
      });
    },

    getObjectDropdownData({ commit, state, dispatch }, This) {
      commit("SET_LOADING", true);
      const idLocatei = localStorage.getItem("locateiId");
      axios.defaults.headers.common["Authorization"] = "bearer " + sessionStorage.getItem("token");
      // axios.get(apiBizagi + "/api/ObjectContract/OverviewOfObject?Accountid=" + idLocatei)
      axios.get(apiBizagi + "/api/PostObjectApi/GetallObjectsName?Accountid=" + idLocatei)
        .then(({ data }) => {
          console.log("ObjectDropdownData", data);
          data = data.map(obj => {

            return {
              value: obj.idObjects,

              label: `${obj.SerialNumber} (${obj.ExternalReference})`,
            }
          })

          commit("SET_OBJECTDROPDOWNDATA", data);
          commit("SET_LOADING", false);

        }).catch(error => {
          console.log('Error', error.message);
          console.log("ERROR", error.response.status);
          commit("SET_LOADING", false);
          if (error.response.status == 401) {
            This.this.$router.push({ name: "login" });
          } else {
            This.this.$notify({
              group: 'foo',
              type: 'error',
              text: error.message,
              duration: nTime,
            });
          }
        });
    },

    GetServiceTypeData({ commit, state, dispatch }, This) {
      commit("SET_LOADING", true);

      axios.defaults.headers.common["Authorization"] = "bearer " + sessionStorage.getItem("token");
      axios.get(apiBizagi + "/api/PostObjectApi/GetAllServiceType")
        .then(({ data }) => {

          data = data.map(obj => {

            return {
              value: obj.idServiceType,
              label: obj.ServiceTypeName
            }
          })

          commit("SET_SERVICETYPE", data);
          commit("SET_LOADING", false);

        }).catch(error => {
          console.log('Error', error.message);
          console.log("ERROR", error.response.status);
          commit("SET_LOADING", false);
          if (error.response.status == 401) {
            This.this.$router.push({ name: "login" });
          } else {
            This.this.$notify({
              group: 'foo',
              type: 'error',
              text: error.message,
              duration: nTime,
            });
          }
        });
    },
    postCreateNewService({ commit, state, dispatch }, This) {
      commit("SET_LOADING", true);
      const sendData = {
        ServiceType: This.typemelding,
        Description: This.description,
        idObjects: This.idObject,
        ObjectErrorCode: This.foutCodeObject,
        ServiceReference: This.reffrentieNummer,
        MachineHours: This.tellerStand,
        PostFilesdata: This.PostFilesdata,
      };

      console.log('checking service sendata', sendData);

      axios.defaults.headers.common["Authorization"] = "bearer " + sessionStorage.getItem("token");
      axios.post(apiBizagi + "api/PostObjectApi/Createnotification", sendData).then(({ data, status }) => {


        if (status == 200) {
          This.this.$notify({
            group: 'foo',
            type: 'success',
            text: "Nieuwe service aangevraagd",
            duration: nTime,
          });
        }


        commit("SET_LOADING", false);
      }).catch(error => {
        console.log('Error', error.message);
        console.log("ERROR", error.response.status);
        commit("SET_LOADING", false);

        if (error.response.status == 400) {
          This.this.$notify({
            group: 'foo',
            type: 'error',
            // text: "Het maken van de service is mislukt. Upload het bestand in minder dan 10 MB.",
            text: error.message,
            duration: nTime,
          });
        }
        else if (error.response.status == 401) {
          This.this.$router.push({ name: "login" });
        } else {
          This.this.$notify({
            group: 'foo',
            type: 'error',
            text: error.message,
            duration: nTime,
          });
        }
      });
    },

    postCreateUser({ commit, state, dispatch }, This) {
      commit("SET_LOADING", true);
      const sendData = {
        Name: This.name,
        Email: This.email,
        Phone: This.phone,
        PortalUserSettings: This.portalUserSettings,
        AccountSF: This.idAccount,
        idContactPersons: This.idContactPersons,
      };

      console.log(sendData);

      axios.defaults.headers.common["Authorization"] = "bearer " + sessionStorage.getItem("token");
      axios.post(apiBizagi + "api/PostObjectApi/PostContactUserDtails", sendData).then(({ data, status }) => {


        // if (status == 200) {
        //   This.this.$notify({
        //     group: 'foo',
        //     type: 'success',
        //     text: "Nieuwe contact user added",
        //     duration: nTime,
        //   });
        // }
        console.log("create contact user", data);
        commit("SET_LOADING", false);
      }).catch(error => {
        console.log('Error', error.message);
        console.log("ERROR", error.response.status);
        commit("SET_LOADING", false);
        if (error.response.status == 401) {
          This.this.$router.push({ name: "login" });
        } else {
          This.this.$notify({
            group: 'foo',
            type: 'error',
            text: error.message,
            duration: nTime,
          });
        }
      });
    },


    editUser({ commit, state, dispatch }, This) {
      commit("SET_LOADING", true);
      const sendData = {
        idContactPersons: This.idContactPersons,
        idAccountSF: This.idAccountSF,
        PortalUserSettings: This.PortalUserSettings,
        Name: This.Name,
        Email: This.Email,
        Phone: This.Phone,
      };

      console.log(sendData);

      axios.defaults.headers.common["Authorization"] = "bearer " + sessionStorage.getItem("token");
      axios.put(apiBizagi + "api/PostObjectApi/EditTheUsers", sendData).then(({ data, status }) => {

        console.log("edit user", data);
        commit("SET_LOADING", false);
      }).catch(error => {
        console.log('Error', error.message);
        console.log("ERROR", error.response.status);
        commit("SET_LOADING", false);
        if (error.response.status == 401) {
          This.this.$router.push({ name: "login" });
        } else {
          This.this.$notify({
            group: 'foo',
            type: 'error',
            text: error.message,
            duration: nTime,
          });
        }
      });
    },

    deleteUser({ commit, state, dispatch }, This) {
      commit("SET_LOADING", true);
      const sendData = {
        idContactPersons: This.idContactPersons
      };
      console.log(sendData);
      axios.defaults.headers.common["Authorization"] = "bearer " + sessionStorage.getItem("token");
      axios.put(apiBizagi + "api/PostObjectApi/DeleteUsers", sendData).then(({ data, status }) => {
        console.log("delete user", data);
        commit("SET_LOADING", false);
      }).catch(error => {
        console.log('Error', error.message);
        console.log("ERROR", error.response.status);
        commit("SET_LOADING", false);
        if (error.response.status == 401) {
          This.this.$router.push({ name: "login" });
        } else {
          This.this.$notify({
            group: 'foo',
            type: 'error',
            text: error.message,
            duration: nTime,
          });
        }
      });
    },

  },
  modules: {
  }
})
