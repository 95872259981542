import { render, staticRenderFns } from "./VlootTable.vue?vue&type=template&id=26e8bc82&scoped=true&"
import script from "./VlootTable.vue?vue&type=script&lang=js&"
export * from "./VlootTable.vue?vue&type=script&lang=js&"
import style0 from "./VlootTable.vue?vue&type=style&index=0&id=26e8bc82&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26e8bc82",
  null
  
)

export default component.exports
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSelect,QInput,QIcon,QTable,QTr,QTd,QAvatar,QBtn,QMenu,QList,QItem,QDialog,QCard,QCardSection,QSpace,QDate,QScrollArea});qInstall(component, 'directives', {ClosePopup});
