import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import Login from '../views/Login.vue'
import Login from '../views/NewLogin.vue'
import Vloot from '../components/Vloot.vue'
import Contracten from '../components/Contracten.vue'
import Activiteit from '../components/Activiteit.vue'
import User from '../components/User.vue'
import VlootTable from '../components/VlootTable.vue'
import Machinedetails from '../components/Machinedetails.vue'
import ContractenTable from '../components/ContractenTable.vue'
import ContractenDesc from '../components/ContractenDesc.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,

  },
  // {
  //   path: '/',
  //   name: 'Login',
  //   component: NewLogin,
  // },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    // redirect: { name: 'Vloot' },
    children: [
      {
        path: '/Vloot',
        name: 'Vloot',
        component: Vloot,
        redirect: { name: 'VlootTable' },
        children: [
          {
            path: '/VlootTable',
            name: 'VlootTable',
            component: VlootTable
          },
          {
            path: '/Machinedetails',
            name: 'Machinedetails',
            component: Machinedetails
          },
        ],
      },
      {
        path: '/Contracten',
        name: 'Contracten',
        component: Contracten,
        redirect: { name: 'ContractenTable' },
        children: [
          {
            path: '/ContractenTable',
            name: 'ContractenTable',
            component: ContractenTable
          },
          {
            path: '/ContractenDesc',
            name: 'ContractenDesc',
            component: ContractenDesc
          },
        ]
      },
      {
        path: '/Activiteit',
        name: 'Activiteit',
        component: Activiteit
      },
      {
        path: '/User',
        name: 'User',
        component: User
      },
    ],
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: "history",
  // base: '/MOTRAC',
  // base: '/motrac_serviceportal_dev', //new Dev
  base: '/',  //Azure
  // base: '/TestingMotrac',
  routes,
})

export default router
