import { render, staticRenderFns } from "./Machinedetails.vue?vue&type=template&id=ecbd8e12&scoped=true&"
import script from "./Machinedetails.vue?vue&type=script&lang=js&"
export * from "./Machinedetails.vue?vue&type=script&lang=js&"
import style0 from "./Machinedetails.vue?vue&type=style&index=0&id=ecbd8e12&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ecbd8e12",
  null
  
)

export default component.exports
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSplitter from 'quasar/src/components/splitter/QSplitter.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QPopupEdit from 'quasar/src/components/popup-edit/QPopupEdit.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QIcon,QSplitter,QTabs,QTab,QBtn,QTabPanels,QTabPanel,QScrollArea,QCard,QTable,QTr,QTd,QPopupEdit,QInput,QDialog,QCardSection,QSpace,QAvatar});qInstall(component, 'directives', {ClosePopup});
