<template>
  <div>
    <transition name="slide" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    };
  },
  methods:{
    
  },
  
};
</script>
<style lang="scss" scoped>
</style>