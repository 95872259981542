<template>
  <div class="machine-details">
    <!-- <h1> {{$route.query.id }}</h1> -->
    <div class="row location-div">
      <div class="col-md-6 col-lg-6 col-sm-0 col-xs-0"></div>
      <div class="col-md-6 col-lg-6 col-sm-0 col-xs-0">
        <div class="location-icone-div">
          <div class="location-name">
            {{ accountName }}
          </div>
          <div class="location-icone">
            <q-icon name="arrow_right" size="50px" />
          </div>
          <div class="object-id">
            {{ serialNumber }}
          </div>
        </div>
      </div>
    </div>

    <q-splitter
      v-model="splitterModel"
      :separator-style="{ display: 'none' }"
      class="spliter-box"
    >
      <template v-slot:before>
        <q-tabs
          v-model="tab"
          vertical
          indicator-color="transparent"
          inline-label
          align="left"
          active-bg-color="mt-red"
        >
          <!-- icon="article"
                  label="BEKIJK ACTIVITEIT" -->
          <div class="row">
            <div class="col-md-9 col-lg-9 col-sm-0 col-xs-0">
              <div style="    margin-left: -45px;">
                <q-tab name="bekijkTab" class="tab-back-gray-color">
                  <img
                    src="../assets/LMH_Icons_safety_mg.svg"
                    alt=""
                    srcset=""
                    style="height:30px;width:30px"
                    v-if="tab === 'bekijkTab'"
                  />
                  <img
                    src="../assets/LMH_Icons_safety_mg_red.svg"
                    alt=""
                    srcset=""
                    style="height:30px;width:30px"
                    v-else
                  />

                  <div class="tab-img-title">BEKIJK ACTIVITEIT</div>
                </q-tab>
              </div>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-0 col-xs-0"></div>
          </div>

          <div class="row">
            <div class="col-md-9 col-lg-9 col-sm-0 col-xs-0">
              <q-tab name="bekijkTruckTab" class="tab-back-gray-color">
                <img
                  src="../assets/LMH_Icons_forklift_mg.svg"
                  alt=""
                  srcset=""
                  style="height:30px;width:30px"
                  v-if="tab === 'bekijkTruckTab'"
                />
                <img
                  src="../assets/LMH_Icons_forklift_mg copy.svg"
                  alt=""
                  srcset=""
                  style="height:30px;width:30px"
                  v-else
                />
                <div class="tab-img-title">Bekijk truck informatie</div>
              </q-tab>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-0 col-xs-0"></div>
          </div>

          <div class="row">
            <div class="col-md-9 col-lg-9 col-sm-0 col-xs-0">
              <div style="    margin-left: -58px;">
                <q-tab name="bekijkContractTab" class="tab-back-gray-color">
                  <img
                    src="../assets/LMH_Icons_cash_euro_mg.svg"
                    alt=""
                    srcset=""
                    style="height:30px;width:30px"
                    v-if="tab === 'bekijkContractTab'"
                  />
                  <img
                    src="../assets/LMH_Icons_cash_euro_mg copy.svg"
                    alt=""
                    srcset=""
                    style="height:30px;width:30px"
                    v-else
                  />
                  <div class="tab-img-title">Bekijk contract</div>
                </q-tab>
              </div>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-0 col-xs-0"></div>
          </div>

          <div class="row" style="color:#A93135;">
            <q-btn flat label="Terug naar overzicht" @click="goBackHome" />
          </div>
        </q-tabs>
      </template>

      <template v-slot:after>
        <q-tab-panels
          v-model="tab"
          animated
          swipeable
          vertical
          transition-prev="jump-up"
          transition-next="jump-up"
          class="bg-transperent"
        >
          <q-tab-panel name="bekijkTab">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-0 col-xs-0">
                <div class="meldingen-tab-info">TRUCK ACTIVITEIT</div>
                <div class="notification-div q-py-md">
                  <!-- <div class="date-div q-pb-sm">
                    20 JUNI 2021
                  </div> -->
                  <q-scroll-area
                    :thumb-style="thumbStyle"
                    :bar-style="barStyle"
                    class="scroll-area-section"
                    v-if="specificobjectnotificationdata.length > 0"
                  >
                    <div
                      style="padding: 2px;     margin-bottom: 5px;"
                      v-for="(objectnotification,
                      index) in specificobjectnotificationdata"
                      :key="index"
                    >
                      <div style="padding: 2px;     margin-bottom: 5px;">
                        <q-card @click="showDescription(objectnotification)">
                          <div
                            class="row"
                            style="align-items: center; width: 100%;"
                          >
                            <div
                              class="col-md-2 col-lg-2 col-sm-0 col-xs-0"
                              style="align-self: stretch;"
                            >
                              <div
                                style="height:100%;background-color:#333434; color:white ;text-align:center;display: flex;
    align-items: center;justify-content:center;"
                              >
                                <div>
                                  <h6 style="padding-top:8px">
                                    {{ objectnotification.Day }}
                                    {{ objectnotification.Month }}
                                  </h6>
                                  <h5>{{ objectnotification.Year }}</h5>
                                </div>
                              </div>
                              <!-- <div class="red-notification"></div> -->
                            </div>
                            <div
                              class="col-md-7 col-lg-7 col-sm-0 col-xs-0"
                              style="padding-left: 18px;     text-align: justify;"
                            >
                              <div class="object-name-gray q-py-xs">
                                {{ objectnotification.Title }}
                                <!-- Lorem ipsum dolor, sit amet -->
                              </div>
                              <div class="object-desc q-py-xs">
                                {{ objectnotification.Description }}
                                <!-- Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Asperiores fuga -->
                              </div>
                            </div>
                            <div
                              class="col-md-3 col-lg-3 col-sm-0 col-xs-0"
                              style="text-align: center;"
                            >
                              <q-btn
                                outline
                                style="color:#333434; width:117px;"
                                :label="
                                  objectnotification.PortalActivityTypeTag
                                "
                              />
                              <!-- @click="
                                  card_description_el = !objectnotification.DescriptiveElement
                                " -->
                            </div>
                          </div>
                        </q-card>
                        <div
                          class="card-description"
                          v-show="
                            objectnotification.DescriptiveElement &&
                              (objectnotification.RequestUser !== null ||
                                objectnotification.ServiceTypeName !== null ||
                                objectnotification.ServiceStatusName !== null ||
                                objectnotification.ServicesDescription !== null)
                          "
                        >
                          <div
                            class="row card-description-row"
                            style="text-align: start;"
                          >
                            <div class="col-3 card-description-column">
                              Aanvrager
                            </div>
                            <div class="col-9">
                              {{ objectnotification.RequestUser }}
                            </div>
                          </div>
                          <div class="row card-description-row">
                            <div class="col-3 card-description-column">
                              Type service
                            </div>
                            <div class="col-9">
                              {{ objectnotification.ServiceTypeName }}
                            </div>
                          </div>
                          <div class="row card-description-row">
                            <div class="col-3 card-description-column">
                              Status
                            </div>
                            <div class="col-9">
                              {{ objectnotification.ServiceStatusName }}
                            </div>
                          </div>
                          <div
                            class="row card-description-row"
                            style="font-weight:600"
                          >
                            Omschrijving
                          </div>
                          <div class="row card-description-row">
                            {{ objectnotification.ServicesDescription }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </q-scroll-area>
                  <div
                    style="padding: 50px;
                           color: red;
                           font-size: 19px;"
                    v-else
                  >
                    Geen actieve meldingen
                  </div>
                  <q-card v-if="false">
                    <div class="row" style="align-items: center;">
                      <div
                        class="col-md-2 col-lg-2 col-sm-0 col-xs-0"
                        style="height:100%"
                      >
                        <div
                          style="height:100%;background-color:#CD282A; color:white ;text-align:center;"
                        >
                          <h6 style="padding-top:8px">20 JUNI</h6>
                          <h5>2021</h5>
                        </div>
                        <!-- <div class="red-notification"></div> -->
                      </div>
                      <div
                        class="col-md-7 col-lg-7 col-sm-0 col-xs-0"
                        style="padding-left: 18px;     text-align: justify;"
                      >
                        <div class="object-name-gray q-py-xs">
                          Servicebeurt geannuleerd
                        </div>
                        <div class="object-desc q-py-xs">
                          De servicebeurt voor 26 juni 2021 is geannuleerd
                          vanwege: storing is …
                        </div>
                      </div>
                      <div
                        class="col-md-3 col-lg-3 col-sm-0 col-xs-0"
                        style="text-align: center;"
                      >
                        <q-btn
                          outline
                          style="color:#CD282A; width:117px;"
                          label="OBJECT"
                          @click="card_description_el = !card_description_el"
                        />
                      </div>
                    </div>
                  </q-card>
                </div>
              </div>
              <!-- <div class="col-md-2 col-lg-2 col-sm-0 col-xs-0"></div> -->
            </div>
          </q-tab-panel>

          <q-tab-panel name="bekijkTruckTab">
            <div class="row">
              <div class="col-md-10 col-lg-10 col-sm-0 col-xs-0">
                <div class="meldingen-tab-info">TRUCK INFORMATIE</div>
                <q-table
                  :data="dataObjInfo"
                  :columns="columnsObjeInfo"
                  class="informative-table no-shadow"
                  hide-header
                  hide-bottom
                  :rows-per-page-options="[0]"
                  :pagination.sync="pagination"
                  bordered
                  separator="cell"
                >
                  <template v-slot:body="props">
                    <q-tr :props="props" class="cursor-pointer">
                      <q-td key="infoColum1" :props="props" auto-width>
                        <div>
                          {{ props.row.infoRowName }}
                        </div>
                      </q-td>
                      <q-td key="infoColum2" :props="props" auto-width>
                        <div v-if="props.row.infoRowName == 'Uw referentie'">
                          <div>
                            <q-btn
                              :label="props.row.infoRowDesc"
                              icon-right="edit"
                              no-caps
                              flat
                              class="text-grey"
                              @click="
                                objectnummerDialog = true;
                                editObjNummer = true;
                              "
                            />
                          </div>
                          <!-- <q-popup-edit v-model="props.row.infoRowDesc">
                            <q-input
                              v-model="props.row.infoRowDesc"
                              dense
                              borderless
                              @keyup.enter="updateObject(props.row.infoRowDesc)"
                            />
                          </q-popup-edit> -->
                        </div>
                        <div
                          v-else-if="
                            props.row.infoRowName == 'Tellerstand (datum)'
                          "
                        >
                          <div>
                            <q-btn
                              :label="props.row.infoRowDesc"
                              icon-right="edit"
                              no-caps
                              flat
                              color="grey"
                              @click="
                                medium = true;
                                editHour = true;
                              "
                            />
                          </div>
                          <!-- <q-dialog v-model="medium">
                            <q-card style="width: 700px; max-width: 80vw; ">
                              <q-card-section
                                class="row items-center q-pb-none"
                              >
                                <div class="text-h6">TELLERSTAND WIJZIGEN</div>
                                <q-space />
                                <q-btn
                                  icon="close"
                                  flat
                                  round
                                  dense
                                  v-close-popup
                                />
                              </q-card-section>
                              <div class="q-py-md"></div>
                              <q-card-section>
                                <div class="row q-py-md">
                                  <div class="col-lg-1 col-md-1"></div>
                                  <div class="col-lg-4 col-md-4 g-t-popup-text">
                                    TRUCK
                                  </div>
                                  <div class="col-lg-1 col-md-1"></div>
                                  <div class="col-lg-5 col-md-5">
                                    <div style="display:flex;">
                                      <q-avatar
                                        size="8px"
                                        color="red"
                                        style="display: inline-block; margin: 6px 1px 1px;"
                                        v-if="status"
                                      />
                                      <q-avatar
                                        size="8px"
                                        color="green"
                                        style="display: inline-block;  margin: 6px 1px 1px;"
                                        v-else
                                      />
                                      <div class="q-pl-sm g-t-popup-text">
                                        {{ externalReference }} ({{
                                          serialNumber
                                        }})
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-1 col-md-1"></div>
                                </div>
                                <div class="row q-py-md">
                                  <div class="col-lg-1 col-md-1"></div>
                                  <div class="col-lg-4 col-md-4 g-t-popup-text">
                                    Tellerstand
                                  </div>
                                  <div class="col-lg-1 col-md-1"></div>
                                  <div class="col-lg-5 col-md-5">
                                    <div style="display:flex;">
                                      <div class="q-pl-sm">
                                        {{ props.row.infoRowDesc }}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-1 col-md-1"></div>
                                </div>
                                <div class="row q-py-md">
                                  <div class="col-lg-1 col-md-1"></div>
                                  <div class="col-lg-4 col-md-4 g-t-popup-text">
                                    Nieuwe Tellerstand
                                  </div>
                                  <div class="col-lg-1 col-md-1"></div>
                                  <div class="col-lg-5 col-md-5">
                                    <div style="display:flex;">
                                      <div class="q-pl-sm" v-show="editHour">
                                        <q-input
                                          dense
                                          outlined
                                          rounded
                                          v-model="newInputHoursModel"
                                          :rules="[warningInputValidation]"
                                        />
                                        <div
                                          v-show="warningInputPopup"
                                          class="shadow-5"
                                        >
                                          <div style="display:flex;">
                                            <div class="q-pa-sm">
                                              <q-icon
                                                name="warning"
                                                size="sm"
                                                class="warning-input-val"
                                              />
                                            </div>
                                            <div>
                                              Nieuwe tellerstand mag niet lager
                                              zijn dan de vorige tellerstand
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="q-pl-sm" v-show="!editHour">
                                        {{ newInputHoursModel }}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-1 col-md-1"></div>
                                </div>
                                <div class="q-py-lg"></div>
                                <div class="row q-py-md" v-show="!editHour">
                                  <div class="col-lg-1 col-md-1"></div>
                                  <div class="col-lg-5 col-md-5">
                                    <div class="wijzigen-block-text">
                                      Status gebruiker toevoegen
                                    </div>
                                  </div>
                                  <div class="col-lg-6 col-md-6 q-pl-lg">
                                    <div class="in-validation">
                                      In behandeling
                                    </div>
                                  </div>
                                </div>
                               
                                <div class="q-pt-lg"></div>
                                <div style="text-align:center;" class="q-pt-lg">
                                  <q-btn
                                    class="opslaan-btn"
                                    label="OPSLAAN"
                                    @click="updateHours()"
                                    v-if="editHour"
                                  />
                                  <q-btn
                                    class="opslaan-btn text-white"
                                    outline
                                    label="CLOSE"
                                    v-else-if="!editHour"
                                    @click="medium = false"
                                  />
                                </div>
                              </q-card-section>
                            </q-card>
                          </q-dialog> -->
                          <q-dialog v-model="medium">
                            <q-card style="width: 600px; max-width: 80vw;">
                              <q-card-section
                                class="row items-center q-pb-none"
                              >
                                <div class="text-h6">TELLERSTAND WIJZIGEN</div>
                                <q-space />
                                <q-btn
                                  icon="close"
                                  flat
                                  round
                                  dense
                                  v-close-popup
                                />
                              </q-card-section>

                              <q-card-section>
                                <div class="q-py-lg"></div>
                                <div class="row">
                                  <div class="col-lg-5 col-md-5">
                                    <dir class="wijzigen-block-text">Truck</dir>
                                  </div>
                                  <div class="col-lg-7 col-md-7">
                                    <div style="display:flex;">
                                      <q-avatar
                                        size="8px"
                                        color="red"
                                        class="q-mt-xs"
                                        v-if="status"
                                      />
                                      <q-avatar
                                        size="8px"
                                        color="green"
                                        class="q-mt-xs"
                                        v-else
                                      />
                                      <div class="wijzigen-block-text q-pl-sm">
                                        {{ serialNumber }} ({{
                                          externalReference
                                        }})
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="q-py-lg"></div>
                                <div class="row">
                                  <div class="col-lg-5 col-md-5  col-sm-12">
                                    <div
                                      class="wijzigen-block-text"
                                      style="height:30px"
                                    >
                                      Tellerstand
                                    </div>
                                  </div>
                                  <div
                                    class="col-lg-7 col-md-7  col-sm-12"
                                    style="height:30px"
                                  >
                                    {{ props.row.infoRowDesc }}
                                  </div>
                                </div>
                                <div class="q-py-lg"></div>
                                <div class="row">
                                  <div class="col-lg-5 col-md-5">
                                    <div class="wijzigen-block-text">
                                      Nieuwe Tellerstand
                                    </div>
                                  </div>
                                  <div
                                    class="col-lg-7 col-md-7"
                                    v-show="editHour"
                                  >
                                    <q-input
                                      dense
                                      rounded
                                      outlined
                                      placeholder="Nieuwe tellerstand..."
                                      v-model="newInputHoursModel"
                                      :rules="[warningInputValidation]"
                                      type="number"
                                    >
                                    </q-input>
                                    <div
                                      v-show="warningInputPopup"
                                      class="shadow-5"
                                    >
                                      <div style="display:flex;">
                                        <div class="q-pa-sm">
                                          <q-icon
                                            name="warning"
                                            size="sm"
                                            class="warning-input-val"
                                          />
                                        </div>
                                        <div>
                                          Nieuwe tellerstand mag niet lager zijn
                                          dan de vorige tellerstand
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-lg-7 col-md-7"
                                    v-show="!editHour"
                                  >
                                    {{ newInputHoursModel }}
                                  </div>
                                </div>
                                <div class="q-py-lg"></div>
                                <div class="q-py-lg"></div>

                                <div class="row" v-show="!editHour">
                                  <div class="col-lg-5 col-md-5">
                                    <div class="wijzigen-block-text">
                                      Status tellerstand wijzigen
                                    </div>
                                  </div>
                                  <div class="col-lg-7 col-md-7 q-pl-lg">
                                    <div class="in-validation">
                                      In behandeling
                                    </div>
                                  </div>
                                </div>
                                <div class="q-py-lg"></div>
                                <div class="opslaan-btn-div">
                                  <q-btn
                                    class="opslaan-btn text-white"
                                    outline
                                    label="OPSLAAN"
                                    :disable="newInputHoursModel.length === 0"
                                    @click="updateHours()"
                                    v-if="editHour"
                                  >
                                  </q-btn>
                                  <q-btn
                                    class="opslaan-btn text-white"
                                    outline
                                    label="CLOSE"
                                    v-else-if="!editHour"
                                    @click="medium = false"
                                  >
                                  </q-btn>
                                </div>

                                <div class="q-py-sm"></div>
                              </q-card-section>
                            </q-card>
                          </q-dialog>
                        </div>
                        <div v-else>
                          {{ props.row.infoRowDesc }}
                        </div>
                      </q-td>
                    </q-tr>
                  </template>
                </q-table>
              </div>
              <div class="col-md-2 col-lg-2 col-sm-0 col-xs-0"></div>
            </div>
          </q-tab-panel>

          <q-tab-panel name="bekijkContractTab">
            <div class="row">
              <div class="col-md-10 col-lg-10 col-sm-0 col-xs-0">
                <div class="meldingen-tab-info">CONTRACT INFORMATIE</div>
                <q-table
                  :data="dataContInfo"
                  :columns="columnsContInfo"
                  hide-header
                  hide-bottom
                  class="contract-table no-shadow"
                  :rows-per-page-options="[0]"
                  :pagination.sync="pagination"
                  bordered
                  separator="cell"
                >
                  <template v-slot:body="props">
                    <q-tr :props="props" class="cursor-pointer">
                      <q-td key="contColm1" :props="props" auto-width>
                        <div>
                          {{ props.row.contName }}
                        </div>
                      </q-td>
                      <q-td key="contColm2" :props="props" auto-width>
                        <div>
                          {{ props.row.contDesc }}
                        </div>
                      </q-td>
                    </q-tr>
                  </template>
                </q-table>
              </div>
              <div class="col-md-2 col-lg-2 col-sm-0 col-xs-0"></div>
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </template>
    </q-splitter>

    <!-- uw referente popup -->

    <q-dialog v-model="objectnummerDialog">
      <q-card style="width: 600px; max-width: 80vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">UW REFERENTIE WIJZIGEN</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section>
          <div class="q-py-lg"></div>
          <div class="row">
            <div class="col-lg-5 col-md-5">
              <div class="objectnummer-block-text">Huidige referentie</div>
            </div>
            <div class="col-lg-7 col-md-7">
              <div style="display:flex;">
                <q-avatar
                  size="8px"
                  color="red"
                  class="q-mt-xs"
                  v-if="status"
                />
                <q-avatar size="8px" color="green" class="q-mt-xs" v-else />
                <div class="objectnummer-block-text q-pl-sm">
                  {{ serialNumber }} ({{ externalReference }})
                </div>
              </div>
            </div>
          </div>
          <div class="q-py-lg"></div>
          <div class="row">
            <div class="col-lg-5 col-md-5">
              <div class="objectnummer-block-text">
                Nieuwe referentie
              </div>
            </div>
            <div class="col-lg-7 col-md-7" v-show="editObjNummer">
              <q-input
                dense
                outlined
                rounded
                placeholder="Vul uw nieuwe referentie in..."
                v-model="objNummer"
              />
            </div>
            <div class="col-lg-7 col-md-7" v-show="!editObjNummer">
              {{ objNummer }}
            </div>
          </div>
          <div class="q-py-lg"></div>

          <div class="q-py-lg"></div>
          <div class="q-py-lg"></div>

          <div class="row" v-show="!editObjNummer">
            <div class="col-lg-5 col-md-5">
              <div class="objectnummer-block-text">
                Status objectnummer wijzigen
              </div>
            </div>
            <div class="col-lg-7 col-md-7 q-pl-lg">
              <div class="in-validation">In behandeling</div>
            </div>
          </div>
          <div class="q-py-lg"></div>
          <div class="opslaan-btn-div">
            <q-btn
              class="opslaan-btn text-white"
              outline
              label="OPSLAAN"
              @click="updateObjectNummer"
              :disable="objNummer.length === 0"
              v-if="editObjNummer"
            >
            </q-btn>
            <q-btn
              class="opslaan-btn text-white"
              outline
              label="CLOSE"
              @click="objectnummerDialog = false"
              v-else-if="!editObjNummer"
            >
            </q-btn>
          </div>
          <div class="q-py-sm"></div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
export default {
  created() {
    this.accountName = localStorage.getItem("AccountName");
    this.serialNumber = localStorage.getItem("SerialNumber");
    this.idContracts = localStorage.getItem("idContracts");
    this.idContactPerson = localStorage.getItem("idContactPerson");

    this.status = localStorage.getItem("Status");
    console.log("Debugging status", this.status);
    this.externalReference = localStorage.getItem("ExternalReference");
    console.log("fromPath", localStorage.getItem("fromPath"));
    if (localStorage.getItem("fromPath") == "menu") {
      this.tab = "bekijkContractTab";
    }
    this.getSpecificObjectNotification();
    this.getObjectInfoData();
    this.getSpecificContractDetails();
  },
  computed: {
    ...mapState({
      specificobjectnotificationdata: "specificobjectnotificationdata",
    }),
  },
  data() {
    return {
      tellerstandWijzigen: false,
      objNummer: "",
      editObjNummer: true,
      objectnummerDialog: false,
      editHour: true,
      warningInputPopup: false,
      idContactPerson: null,
      idContracts: null,
      newInputHoursModel: "",
      accountName: null,
      serialNumber: null,
      status: null,
      externalReference: null,
      card_description_el: false,
      medium: false,
      tab: "bekijkTruckTab",
      splitterModel: 50,
      thumbStyle: {
        right: "4px",
        borderRadius: "5px",
        backgroundColor: "#a00020",
        width: "5px",
        opacity: 0.75,
      },
      barStyle: {
        right: "2px",
        borderRadius: "9px",
        backgroundColor: "#a00020",
        width: "9px",
        opacity: 0.2,
        show: false,
        elementColor: "red",
      },
      columnsObjeInfo: [
        {
          name: "infoColum1",
          align: "left",
          label: "OBJECTNUMMER",
          field: "infoRowName",
          sortable: true,
        },
        {
          name: "infoColum2",
          align: "left",
          label: "SERIENUMMER",
          field: "infoRowDesc",
          sortable: true,
        },
      ],
      dataObjInfo: [
        {
          id: 1,
          infoRowName: "Objectnummer",
          infoRowDesc: "",
        },
        {
          id: 2,
          infoRowName: "Uw referentie",
          infoRowDesc: "",
        },
        {
          id: 3,
          infoRowName: "Model",
          infoRowDesc: "",
        },
        // {
        //   id: 4,
        //   infoRowName: "Serienummer",
        //   infoRowDesc: "HJ-01543112233",
        // },
        {
          id: 5,
          infoRowName: "Tellerstand (datum)",
          infoRowDesc: "",
        },
        {
          id: 6,
          infoRowName: "BMWT-status",
          infoRowDesc: "",
        },
        {
          id: 7,
          infoRowName: "BMWT-vervaldatum",
          infoRowDesc: "",
        },
        // {
        //   id: 8,
        //   infoRowName: "Komende servicebeurt (verwacht)",
        //   infoRowDesc: "26-06-2021",
        // },
      ],
      columnsContInfo: [
        {
          name: "contColm1",
          align: "left",
          label: "OBJECTNUMMER",
          field: "contName",
          sortable: true,
        },
        {
          name: "contColm2",
          align: "left",
          label: "SERIENUMMER",
          field: "contDesc",
          sortable: true,
        },
      ],
      dataContInfo: [
        {
          id: 1,
          contName: "Contractnummer",
          contDesc: "",
        },
        {
          id: 2,
          contName: "Begin contract",
          contDesc: "",
        },
        {
          id: 3,
          contName: "Eind contract",
          contDesc: "",
        },
        {
          id: 4,
          contName: "Uren per jaar",
          contDesc: "",
        },
        {
          id: 5,
          contName: "Contract type",
          contDesc: "",
        },
        {
          id: 6,
          contName: "Objectmodel",
          contDesc: "",
        },
      ],
      pagination: {
        page: 1,
        rowsPerPage: 0, // 0 means all rows
      },
    };
  },
  methods: {
    goBackHome() {
      this.$router.push({ name: "Vloot" });
    },

    getObjectInfoData() {
      this.$store.dispatch("getObjectInfoData", {
        this: this,
      });
    },
    getSpecificContractDetails() {
      console.log("specific contract details ");

      this.$store.dispatch("getSpecificContractDetails", {
        this: this,
      });
    },
    getSpecificObjectNotification() {
      console.log("specific contract details ");

      this.$store.dispatch("getSpecificObjectNotification", {
        this: this,
      });
      console.log("rajesh debuging", this.specificobjectnotificationdata);
    },
    showDescription(objectnotification) {
      objectnotification.DescriptiveElement = !objectnotification.DescriptiveElement;
    },
    updateHours() {
      this.medium = this.editHour ? true : false;
      this.editHour = false;

      this.$store.dispatch("postTellerStandHours", {
        this: this,
        idContactPerson: this.idContactPerson ? this.idContactPerson : null,
        idContracts: this.idContracts ? this.idContracts : null,
        newHour: this.newInputHoursModel,
      });
    },
    // updateObject(objNumber) {
    //   console.log("checking new objnumber", objNumber);

    //   this.$store.dispatch("postObjectNumber", {
    //     this: this,
    //     idContactPerson: this.idContactPerson ? this.idContactPerson : null,
    //     idContracts: this.idContracts ? this.idContracts : null,
    //     objectNumber: objNumber,
    //   });
    // },
    updateObjectNummer() {
      this.objectnummerDialog = this.editObjNummer ? true : false;
      this.editObjNummer = false;

      this.$store.dispatch("postObjectNumber", {
        this: this,
        idContactPerson: this.idContactPerson ? this.idContactPerson : null,
        idContracts: this.idContracts ? this.idContracts : null,
        objectNumber: this.objNummer,
      });
    },
    warningInputValidation(val) {
      let existingHour = this.dataObjInfo[3].infoRowDesc.split("(");
      if (Number(existingHour[0]) < Number(val)) {
        this.warningInputPopup = false;
      } else this.warningInputPopup = true;
      // console.log(this.dataObjInfo[3].infoRowDesc.split("("));
      // this.warningInputPopup = true;
    },
  },
};
</script>
<style scoped>
.machine-details {
  width: 89%;
  margin: auto !important;
  margin-left: 11px;
}
.location-div {
  position: relative;
  z-index: 6000;
  margin-top: -70px;
}
.location-icone-div {
  display: flex;
  padding-left: 17px;
}
.location-name {
  font: normal normal normal 25px/29px Arial;
  letter-spacing: 1.25px;
  color: #a00020;
  text-transform: uppercase;
}
.location-icone {
  margin-top: -9px;
}
.object-id {
  font: normal normal normal 25px/29px Arial;
  letter-spacing: 1.25px;
  color: #000000;
  text-transform: uppercase;
}
.spliter-box {
  padding-top: 55px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: -58px;
  position: relative;
  z-index: 2000;
}
.tab-back-gray-color {
  background: #eeeff3;
  margin-bottom: 25px;
  color: #a00020;
}
.tab-back-gray-color:hover {
  background: #a00020;
  color: white;
}
.text-transperent {
  color: transparent;
}
.bg-transperent {
  background: transparent;
}
.q-tabs {
  padding-top: 55px !important;
}
.scroll-area-section {
  height: calc(100vh - 300px);
  max-width: 100%;
}
.meldingen-tab-info {
  font: normal normal bold 26px/31px Arial;
  letter-spacing: 1.5px;
  color: #000000;
  text-transform: uppercase;
}
.red-notification {
  background: #ba1124 0% 0% no-repeat padding-box;
  width: 95px;
  height: 100%;
}
.object-name-gray {
  font: normal normal bold 17px/21px Arial;
  letter-spacing: 0.9px;
  color: #333434;
}
.object-desc {
  font: normal normal normal 16px/20px Arial;
  letter-spacing: 0.85px;
  color: #333434;
}
.date-div {
  font: normal normal normal 16px/18px Arial;
  letter-spacing: 0.8px;
  color: #333434;
  text-transform: uppercase;
}
.q-table tbody td:before {
  background: none;
}
.informative-table {
  margin-top: 20px;
}
.contract-table {
  margin-top: 20px;
}
.q-table--bordered {
  border: 1px solid #ba1124;
}
.q-table td {
  border-color: #ba1124;
}

.text-mt-red {
  color: #a00020 !important;
}
.bg-mt-red {
  background: #a00020 !important;
  color: white;
}

.opslaan-btn {
  background: #a00020 !important;
  color: white;
}
.opslaan-btn:hover {
  background-color: black !important;
  color: white;
}
.g-t-popup-text {
  font: normal normal bold 17px/20px Arial;
  letter-spacing: 0.85px;
  color: #333434;
}
.q-tab__content {
  margin-left: -48px;
}
.q-tab-panel {
  padding-right: 4px;
}
/* .q-card {
  height: 85px;
  display: flex;
} */
.card-description {
  /* height: 175px; */
  background-color: #eeeff3;
  padding: 20px;
}
.card-description-row {
  padding-bottom: 6px;
}
.card-description-column {
  font-weight: 600;
}
.warning-input-val {
  color: #a00020;
}
.wijzigen-block-text {
  font: normal normal bold 17px/20px Arial;
  letter-spacing: 0.85px;
  color: #333434;
}
.in-validation {
  font: normal normal bold 17px/20px Arial;
  letter-spacing: 0.85px;
  color: #a00020;
}
.objectnummer-block-text {
  font: normal normal bold 17px/20px Arial;
  letter-spacing: 0.85px;
  color: #333434;
}
.items-center,
.flex-center {
  align-items: center;
}
.opslaan-btn-div {
  text-align: center;
}
.tab-img-title {
  margin-left: 7px;
  font-weight: 500;
}
.logo-icon {
  /* background: black; */
  /* filter: invert(1) sepia(1) saturate(5) hue-rotate(175deg); */
  /* filter: invert(0.9); */
  filter: invert(0%) sepia(3%) saturate(7489%) hue-rotate(169deg)
    brightness(98%) contrast(95%);
  filter: grayscale(100%);
}
</style>
