<template>
  <q-layout
    view="lHh Lpr lFf"
    style="height=100%vh !important; overflow:hidden;"
  >
    <notifications group="foo" position="top center" />

    <loading
      :active.sync="isLoading"
      color="#fa8005"
      background-color="#000"
      :z-index="10000"
      :is-full-page="fullPage"
    >
    </loading>

    <transition name="slide" mode="out-in">
      <router-view />
    </transition>
  </q-layout>
</template>
// rajesh

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    Loading,
  },
  computed: {
    ...mapState({
      isLoading: "isLoading",
    }),
  },
  data() {
    return {
      fullPage: true,
    };
  },
};
</script>

<style>
.vue-notification {
  margin-top: 10px;
}
.vue-notification.error {
  box-shadow: 0px 0px 5px 0px #c25f67;
}
.vue-notification.success {
  background-color: rgb(160, 0, 32, 0.5);
  border: 2px solid rgb(160, 0, 32, 0.25);
  box-shadow: 0px 0px 5px 0px rgb(160, 0, 32, 0.5);
}
.vue-notification.warn {
  box-shadow: 0px 0px 5px 0px #fda767;
}
</style>
