<template>
  <div>
    <!-- Wb devlopment -->
    <div class="desktop-vloot-table">
      <div
        class="row"
        style="position: fixed;
    z-index: 4999;
    width: 90%;"
      >
        <div class="col-md-2 col-lg-2 col-sm-0 col-xs-0"></div>
        <div class="col-md-8 col-lg-8 col-sm-0 col-xs-0 search-block">
          <div class="row">
            <div class="col-md-5 col-lg-5 col-sm-0 col-xs-0">
              <div class="localite-seprator-div q-pr-sm q-pl-sm">
                <q-select
                  v-model="Locatie"
                  :options="accountuser"
                  label="Locatie"
                  dense
                  borderless
                  options-dense
                  class="select-localite"
                  @input="getVlootData()"
                />
              </div>
            </div>
            <!-- <div class="col-md-3 col-lg-3 col-sm-0 col-xs-0">
              <div class="categorie-seprator-div q-pr-sm q-pl-sm">
                <q-select
                  v-model="Categorie"
                  :options="categorieOptions"
                  label="Categorie"
                  dense
                  borderless
                  options-dense
                  class="select-categorie"
                />
              </div>
            </div> -->
            <div class="col-md-7 col-lg-7 col-sm-0 col-xs-0">
              <div class="serch-input-div q-pl-sm">
                <q-input
                  v-model="vlootSearchQuery"
                  dense
                  borderless
                  placeholder="Zoek object…."
                  class="search-input"
                >
                </q-input>
                <div class="search-icon-div">
                  <q-icon name="search" size="21px" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-lg-2 col-sm-0 col-xs-0"></div>
      </div>

      <div class="mign-text">
        MIJN VLOOT
      </div>
      <div class="table-div">
        <q-table
          :data="resultVlootQuery"
          :columns="columns"
          :table-header-style="{ backgroundColor: '#a00020', color: 'white' }"
          :pagination.sync="pagination"
        >
          <template v-slot:body="props">
            <q-tr
              :props="props"
              class="cursor-pointer"
              @click="rowDetails(props.row)"
            >
              <!-- <q-td key="statusColoum" :props="props" auto-width>
                <q-avatar size="8px" color="green" />
              </q-td> -->

              <q-td key="UW REFERENTIE" :props="props" auto-width>
                <q-avatar
                  size="8px"
                  color="red"
                  style="display: inline-block; margin:10px;margin-top: 7px;"
                  v-if="props.row.Status"
                />
                <q-avatar
                  size="8px"
                  color="green"
                  style="display: inline-block; margin:10px;margin-top: 7px;"
                  v-else
                />
                <div style="display: inline-block;">
                  {{ props.row.uwReference }}
                </div>
              </q-td>

              <q-td key="ObjectModel" :props="props" auto-width>
                <div>
                  {{ props.row.ObjectModel }}
                </div>
              </q-td>

              <q-td key="BMWT-STATUS" :props="props" auto-width>
                {{ props.row.BMWTStatus }}
              </q-td>

              <q-td key="BMWT-VERVALDATUM" :props="props" auto-width>
                {{ props.row.Month }} {{ props.row.Year }}
              </q-td>

              <q-td key="TellerStand" :props="props" auto-width>
                {{ props.row.MachineHours }} ({{ props.row.MachineHoursDate }})
              </q-td>

              <q-td key="notification" :props="props" auto-width>
                <div style="display:flex; justify-content:center;">
                  <div class="table-notification">
                    {{ props.row.PortalCount }}
                  </div>
                </div>
              </q-td>

              <q-td key="sender" :props="props" auto-width>
                <div class="sender">
                  <q-btn icon="more_vert" color="grey" flat @click.stop="">
                    <q-menu>
                      <q-list style="min-width:190px;">
                        <q-item
                          clickable
                          v-close-popup
                          @click="CallServicePopup(props.row)"
                          style="align-items: center;"
                        >
                          <!-- <q-icon color="grey" name="warning_amber" /> -->
                          <img
                            src="../assets/LMH_Icons_maintenance_mg.svg"
                            alt=""
                            style="height:15px;width:15px"
                          />

                          <div class="q-pl-xs">Melding aanmaken</div>
                        </q-item>
                        <q-item
                          clickable
                          v-close-popup
                          @click="tellerstandPopup(props.row)"
                          style="align-items: center;"
                        >
                          <img
                            src="../assets/LMH_Icons_performance_mg.svg"
                            alt=""
                            style="height:15px;width:15px"
                          />
                          <div class="q-pl-xs">Tellerstand doorgeven</div>
                        </q-item>
                        <q-item
                          clickable
                          v-close-popup
                          @click="objectnummerWijzigenPopup(props.row)"
                          style="align-items: center;"
                        >
                          <img
                            src="../assets/LMH_Icons_inquiry_mg.svg"
                            alt=""
                            style="height:15px;width:15px"
                          />
                          <div class="q-pl-xs">Uw referentie wijzigen</div>
                        </q-item>
                        <q-item
                          clickable
                          v-close-popup
                          @click="gotoContract(props.row)"
                          style="align-items: center;"
                        >
                          <img
                            src="../assets/LMH_Icons_cash_euro_mg.svg"
                            alt=""
                            style="height:15px;width:15px"
                          />
                          <div class="q-pl-xs">Contract inzien</div>
                        </q-item>
                        <q-item
                          clickable
                          v-close-popup
                          @click="inactief(props.row)"
                          style="align-items: center;"
                        >
                          <img
                            src="../assets/LMH_Icons_close-cancel_mg.svg"
                            alt=""
                            style="height:15px;width:15px"
                          />
                          <div class="q-pl-xs">Verwijderen uit lijst</div>
                        </q-item>
                      </q-list>
                    </q-menu>
                  </q-btn>
                </div>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
    </div>
    <q-dialog v-model="tellerstandWijzigen">
      <q-card style="width: 600px; max-width: 80vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">TELLERSTAND WIJZIGEN</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section class="desktop-tellerstand-popup">
          <div class="q-py-lg"></div>
          <div class="row">
            <div class="col-lg-5 col-md-5">
              <dir class="wijzigen-block-text">Truck</dir>
            </div>
            <div class="col-lg-7 col-md-7">
              <div style="display:flex;">
                <q-avatar
                  size="8px"
                  color="red"
                  class="q-mt-xs"
                  v-if="NotificationStatus"
                />
                <q-avatar size="8px" color="green" class="q-mt-xs" v-else />
                <div class="wijzigen-block-text q-pl-sm">
                  {{ SerialNumber }} ({{ ExternalReference }})
                </div>
              </div>
            </div>
          </div>
          <div class="q-py-lg"></div>
          <div class="row">
            <div class="col-lg-5 col-md-5  col-sm-12">
              <div class="wijzigen-block-text" style="height:30px">
                Tellerstand
              </div>
            </div>
            <div class="col-lg-7 col-md-7  col-sm-12" style="height:30px">
              {{ MachineHours }} ({{ MachineHoursDate }})
            </div>
          </div>
          <div class="q-py-lg"></div>
          <div class="row">
            <div class="col-lg-5 col-md-5">
              <div class="wijzigen-block-text">
                Nieuwe Tellerstand
              </div>
            </div>
            <div class="col-lg-7 col-md-7" v-show="editHour">
              <q-input
                dense
                rounded
                outlined
                placeholder="Nieuwe tellerstand..."
                v-model="nieuweUrenstand"
                :rules="[warningInputValidation]"
                type="number"
              >
              </q-input>
              <div v-show="warningInputPopup" class="shadow-5">
                <div style="display:flex;">
                  <div class="q-pa-sm">
                    <q-icon
                      name="warning"
                      size="sm"
                      class="warning-input-val"
                    />
                  </div>
                  <div>
                    Nieuwe tellerstand mag niet lager zijn dan de vorige
                    tellerstand
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-7 col-md-7" v-show="!editHour">
              {{ nieuweUrenstand }}
            </div>
          </div>
          <div class="q-py-lg"></div>
          <div class="q-py-lg"></div>

          <div class="row" v-show="!editHour">
            <div class="col-lg-5 col-md-5">
              <div class="wijzigen-block-text">
                Status tellerstand wijzigen
              </div>
            </div>
            <div class="col-lg-7 col-md-7 q-pl-lg">
              <div class="in-validation">In behandeling</div>
            </div>
          </div>
          <div class="q-py-lg"></div>
          <div class="opslaan-btn-div">
            <!-- :disable="nieuweUrenstand.length === 0 || !editHour" -->
            <q-btn
              class="opslaan-btn text-white"
              outline
              label="OPSLAAN"
              :disable="nieuweUrenstand.length === 0"
              @click="updateHours"
              v-if="editHour"
            >
            </q-btn>
            <q-btn
              class="opslaan-btn text-white"
              outline
              label="CLOSE"
              v-else-if="!editHour"
              @click="tellerstandWijzigen = false"
            >
            </q-btn>
          </div>
          <div class="q-py-sm"></div>
        </q-card-section>
        <q-card-section
          class="mobile-tellerstand-popup"
          style="padding: 0 2em;"
        >
          <div class="q-py-md"></div>
          <div class="row" style="margin-bottom: 3px;">
            <div class="col-12">
              <dir class="wijzigen-block-text">Truck</dir>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div style="display:flex;align-items: baseline;">
                <q-avatar
                  size="8px"
                  color="red"
                  class="q-mt-xs"
                  v-if="NotificationStatus"
                  style="margin-right:4px"
                />
                <q-avatar
                  size="8px"
                  color="green"
                  class="q-mt-xs"
                  v-else
                  style="margin-right:4px"
                />
                <div>{{ SerialNumber }} ({{ ExternalReference }})</div>
              </div>
            </div>
          </div>
          <div class="q-py-md"></div>
          <div class="row" style="margin-bottom: 3px;">
            <div class="col-12">
              <div class="wijzigen-block-text">
                Tellerstand
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              {{ MachineHours }} ({{ MachineHoursDate }})
            </div>
          </div>

          <div class="q-py-md"></div>
          <div class="row" style="margin-bottom: 4px;">
            <div class="col-12">
              <div class="wijzigen-block-text">
                Nieuwe Tellerstand
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12" v-show="editHour">
              <q-input
                dense
                rounded
                outlined
                placeholder="Nieuwe tellerstand..."
                v-model="nieuweUrenstand"
                :rules="[warningInputValidation]"
              >
              </q-input>
              <div v-show="warningInputPopup" class="shadow-5">
                <div style="display:flex;">
                  <div class="q-pa-sm">
                    <q-icon
                      name="warning"
                      size="sm"
                      class="warning-input-val"
                    />
                  </div>
                  <div>
                    Nieuwe tellerstand mag niet lager zijn dan de vorige
                    tellerstand
                  </div>
                </div>
              </div>
            </div>
            <div class=" col-12" v-show="!editHour">
              {{ nieuweUrenstand }}
            </div>
          </div>
          <div class="q-py-lg"></div>

          <div class="row" v-show="!editHour" style="margin-bottom: 3px;">
            <div class="col-lg-5 col-md-5">
              <div class="wijzigen-block-text">
                Status tellerstand wijzigen
              </div>
            </div>
            <div class="col-lg-7 col-md-7 q-pl-lg">
              <div class="in-validation">In behandeling</div>
            </div>
          </div>
          <div class="q-py-lg"></div>
          <div class="opslaan-btn-div">
            <q-btn
              class="opslaan-btn text-white"
              outline
              label="OPSLAAN"
              :disable="nieuweUrenstand.length === 0"
              @click="updateHours"
              v-if="editHour"
            >
            </q-btn>
            <q-btn
              class="opslaan-btn text-white"
              outline
              label="CLOSE"
              v-else-if="!editHour"
              @click="tellerstandWijzigen = false"
            >
            </q-btn>
          </div>
          <div class="q-py-sm"></div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="objectnummerDialog">
      <q-card style="width: 600px; max-width: 80vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">UW REFERENTIE WIJZIGEN</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section>
          <div class="q-py-lg"></div>
          <div class="row">
            <div class="col-lg-5 col-md-5">
              <div class="objectnummer-block-text">Huidige referentie</div>
            </div>
            <div class="col-lg-7 col-md-7">
              <div style="display:flex;">
                <q-avatar
                  size="8px"
                  color="red"
                  class="q-mt-xs"
                  v-if="NotificationStatus"
                />
                <q-avatar size="8px" color="green" class="q-mt-xs" v-else />
                <div class="objectnummer-block-text q-pl-sm">
                  {{ SerialNumber }} ({{ ExternalReference }})
                </div>
              </div>
            </div>
          </div>
          <div class="q-py-lg"></div>
          <div class="row">
            <div class="col-lg-5 col-md-5">
              <div class="objectnummer-block-text">
                Nieuwe referentie
              </div>
            </div>
            <div class="col-lg-7 col-md-7" v-show="editObjNummer">
              <q-input
                dense
                outlined
                rounded
                placeholder="Vul uw nieuwe referentie in..."
                v-model="objNummer"
              />
            </div>
            <div class="col-lg-7 col-md-7" v-show="!editObjNummer">
              {{ objNummer }}
            </div>
          </div>
          <div class="q-py-lg"></div>

          <div class="q-py-lg"></div>
          <div class="q-py-lg"></div>

          <div class="row" v-show="!editObjNummer">
            <div class="col-lg-5 col-md-5">
              <div class="objectnummer-block-text">
                Status objectnummer wijzigen
              </div>
            </div>
            <div class="col-lg-7 col-md-7 q-pl-lg">
              <div class="in-validation">In behandeling</div>
            </div>
          </div>
          <div class="q-py-lg"></div>
          <div class="opslaan-btn-div">
            <q-btn
              class="opslaan-btn text-white"
              outline
              label="OPSLAAN"
              @click="updateObjectNummer"
              :disable="objNummer.length === 0"
              v-if="editObjNummer"
            >
            </q-btn>
            <q-btn
              class="opslaan-btn text-white"
              outline
              label="CLOSE"
              @click="objectnummerDialog = false"
              v-else-if="!editObjNummer"
            >
            </q-btn>
          </div>
          <div class="q-py-sm"></div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="inactiefPopup">
      <q-card style="width: 52%; max-width: 80vw; height:73%">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">TRUCK INACTIEF MAKEN</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section>
          <div class="q-py-lg"></div>
          <div class="row">
            <div class="col-lg-5 col-md-5">
              <div class="inactief-block-text">Serienummer (uw referentie)</div>
            </div>
            <div class="col-lg-7 col-md-7">
              <div style="display:flex;">
                <q-avatar
                  size="8px"
                  color="red"
                  class="q-mt-xs"
                  v-if="NotificationStatus"
                />
                <q-avatar size="8px" color="green" class="q-mt-xs" v-else />
                <div class="inactief-block-text q-pl-sm">
                  {{ uwReference }}
                </div>
              </div>
            </div>
          </div>
          <div class="q-py-sm"></div>
          <div class="row">
            <div class="col-lg-5 col-md-5">
              <div class="inactief-block-text">Inactief vanaf</div>
            </div>
            <div class="col-lg-7 col-md-7">
              <div class="inactief-block-text inactive-input-date-box  q-pl-sm">
                <q-input
                  dense
                  v-model="objectEndDate"
                  label="Datum"
                  rounded
                  borderless
                  readonly
                >
                  <!-- <template v-slot:prepend>
                    <q-icon name="event"> -->
                  <q-menu
                    transition-show="scale"
                    transition-hide="scale"
                    ref="endDateObject"
                  >
                    <q-date
                      minimal
                      color="red-10"
                      v-model="endDate"
                      mask="YYYY-MM-DD"
                      @input="UpdateEndObjectDate()"
                    ></q-date>
                  </q-menu>
                  <!-- </q-icon>
                  </template> -->
                </q-input>

                <!-- {{ Request_EndDateObject }} -->
              </div>
            </div>
          </div>

          <div class="q-py-lg"></div>
          <div class="inactief-block-text">
            Reden voor inactief maken van truck
            <span class="higlihtedStar">*</span>
          </div>
          <div class="q-py-sm"></div>
          <div v-show="editRegion" style="border-radius: 10px;">
            <q-input
              type="textarea"
              rows="4"
              placeholder="Geef reden aan voor het inactief stellen van het object…"
              outlined
              v-model="redenInput"
            />
          </div>
          <div v-show="!editRegion">
            {{ redenInput }}
          </div>
          <div class="q-py-lg"></div>
          <div class="redenHint" v-show="editRegion">
            Let op: na het inactief maken is de truck niet meer zichtbaar in uw
            vlootoverzicht
          </div>
          <div class="row" v-show="!editRegion">
            <div class="col-lg-6 col-md-6">
              <div class="region-block-text">
                Status objectnummer wijzigen
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="region-block-text-red">
                In behandeling
              </div>
            </div>
          </div>
          <div class="q-py-lg"></div>
          <div class="opslaan-btn-div">
            <q-btn
              class="opslaan-btn text-white"
              outline
              label="OPSLAAN"
              @click="ObjectInactBtn"
              :disable="redenInput.length === 0"
              v-if="editRegion"
            >
            </q-btn>
            <q-btn
              class="opslaan-btn text-white"
              outline
              label="CLOSE"
              @click="inactiefPopup = false"
              v-else-if="!editRegion"
            >
            </q-btn>
          </div>
          <div class="q-py-sm"></div>
        </q-card-section>
      </q-card>
    </q-dialog>

    <!-- mobile devlopment -->
    <div class="mobile-vloot-table">
      <div class="row">
        <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
        <div class="col-md-0 col-lg-0 col-sm-10 col-xs-10">
          <div class="q-pt-lg q-pb-lg">
            <q-card>
              <q-input
                v-model="vlootSearchQuery"
                placeholder="Zoek object…"
                class="zoekInputObject"
                borderless
              >
                <template v-slot:append>
                  <q-btn
                    flat
                    icon="search"
                    class="search-icone-Zoek text-white"
                  />
                </template>
              </q-input>
            </q-card>
          </div>
        </div>
        <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
      </div>
      <div class="row">
        <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
        <div class="col-md-0 col-lg-0 col-sm-10 col-xs-10">
          <div
            style="display: flex;
           justify-content: space-between;
           align-items: center;"
          >
            <div class="mb-mijn-heading q-py-md">
              Mijn vloot
            </div>
            <div>
              <q-btn
                icon-right="subdirectory_arrow_left"
                label="Ga terug"
                flat
                no-caps
                color="grey-8"
                @click="goHomePage"
              />
            </div>
          </div>

          <q-scroll-area
            :thumb-style="thumbStyleMB"
            :bar-style="barStyleMB"
            class="mb-card-scrool"
          >
            <div
              class="mb-vloot-notification q-py-md"
              v-for="(dataVloot, index) in resultVlootQuery"
              :key="index"
            >
              <q-card
                class="mb-notification-card q-pa-sm"
                :class="
                  dataVloot.Status
                    ? 'red-color-notification'
                    : 'gray-color-notification'
                "
              >
                <div
                  class="mb-notification-count"
                  v-if="dataVloot.notficationID > 0"
                >
                  {{ dataVloot.notification }}
                </div>

                <div class="mb-card-details">
                  <div>
                    <div class="mb-vloot-heading q-py-xs">
                      <div class="vloot-location">
                        {{ dataVloot.AccountName }}
                      </div>
                      <div class="vloot-popye-icone">
                        <q-icon name="arrow_right" size="25px" />
                      </div>
                      <div class="vloot-object">
                        {{ dataVloot.ExternalReference }}
                      </div>
                    </div>
                    <div class="serialnummer-tellerstand">
                      <div class="mb-serienummer q-py-sm">
                        Serienummer: {{ dataVloot.SerialNumber }}
                      </div>
                      <div class="mb-tellerstand q-py-sm">
                        Tellerstand: {{ dataVloot.MachineHours }} ({{
                          dataVloot.MachineHoursDate
                        }})
                      </div>
                    </div>
                  </div>

                  <div class="mb-card-icone">
                    <!-- <q-icon name="more_vert" size="30px" color="grey" /> -->
                    <q-btn icon="more_vert" color="grey" flat @click.stop="">
                      <q-menu>
                        <q-list style="min-width:190px;">
                          <q-item
                            clickable
                            v-close-popup
                            @click="tellerstandPopup(dataVloot)"
                          >
                            <div>
                              <q-icon color="grey" name="motion_photos_on" />
                            </div>
                            <div class="q-pl-xs">Tellerstand doorgeven</div>
                          </q-item>
                        </q-list>
                      </q-menu>
                    </q-btn>
                  </div>
                </div>
              </q-card>
            </div>
          </q-scroll-area>
        </div>
        <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
      </div>
      <div class="ge-trug-vloot-div">
        <!-- <q-btn
          icon-right="subdirectory_arrow_left"
          label="Ga terug"
          flat
          no-caps
          color="grey-8"
          @click="goHomePage"
        /> -->
      </div>
    </div>
  </div>
</template>
<script>
import ServicePopup from "../components/ServicePopup";
import { mapState, mapGetters } from "vuex";
export default {
  components: {
    ServicePopup,
  },

  created() {
    this.getAccountUsers();
    this.getVlootData();
  },

  computed: {
    ...mapState({
      ServiceMobilePopup: "ServiceMobilePopup",
      accountuser: "accountuser",
      vlootdata: "vlootdata",
      // objectEndDate() {
      //   console.log("raj", this.endDate);
      //   return this.endDate ? this.endDate : null;
      // },
    }),
    resultVlootQuery() {
      if (this.vlootSearchQuery) {
        return this.vlootdata.filter((item) => {
          return (
            this.vlootSearchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.SerialNumber.toLowerCase().includes(v)) ||
            this.vlootSearchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.ExternalReference.toLowerCase().includes(v))
          );
        });
      } else {
        return this.vlootdata;
      }
    },
  },

  data() {
    return {
      objectEndDate: null,

      endDate: null,
      pagination: {
        rowsPerPage: 20,
      },
      tellerstand_mobile: false,
      Request_EndDateObject: null,
      uwReference: null,
      ExternalReference: null,
      SerialNumber: null,
      NotificationStatus: null,
      MachineHours: null,
      MachineHoursDate: null,
      idContactPerson: null,
      idContracts: null,

      vlootSearchQuery: null,
      editRegion: true,
      editHour: true,
      warningInputPopup: false,
      nieuweUrenstand: "",
      objectnummerDialog: false,
      tellerstandWijzigen: false,
      editObjNummer: true,
      inactiefPopup: false,
      objNummer: "",
      redenInput: "",
      Locatie: null,
      Categorie: null,
      search: "",
      locatieOptions: ["Google", "Facebook", "Twitter", "Apple", "Oracle"],
      categorieOptions: ["Google", "Facebook", "Twitter", "Apple", "Oracle"],
      columns: [
        // {
        //   name: "statusColoum",
        //   align: "right",
        // },
        {
          name: "UW REFERENTIE",
          align: "left",
          label: "SERIENUMMER (UW REFERENTIE)",
          field: "UW REFERENTIE",
          sortable: true,
        },
        {
          name: "ObjectModel",
          align: "left",
          label: "MODEL",
          field: "ObjectModel",
          sortable: true,
        },
        {
          name: "BMWT-STATUS",
          align: "left",
          label: "BMWT-STATUS",
          field: "BMWT-STATUS",
          sortable: true,
        },
        {
          name: "BMWT-VERVALDATUM",
          align: "left",
          label: "BMWT-VERVALDATUM",
          field: "BMWT-VERVALDATUM",
          sortable: true,
        },
        {
          name: "TellerStand",
          align: "left",
          label: "TELLERSTAND (DATUM)",
          field: "TellerStand",
          sortable: true,
        },
        {
          name: "notification",
          align: "center",
          label: "ACTIVITEIT",
          field: "notification",
          // style: "max-width: 10px",
        },
        {
          name: "sender",
          align: "center",
        },
      ],
      data: [
        {
          id: 1,
          Objectnummer: "Popeye (KJA-Z001)",
          Locatie: "Leiden",
          Serienummer: 543112233,
          Tellerstand: "2313 uur (01-06-2021)",
          Contract: "31-12-2021",
          notification: 2,
          notficationID: 1,
        },
        {
          id: 2,
          Objectnummer: "Popeye (KJA-Z001)",
          Locatie: "Leiden",
          Serienummer: 543112233,
          Tellerstand: "2313 uur (01-06-2021)",
          Contract: "31-12-2021",
          notification: 5,
          notficationID: 1,
        },
        {
          id: 3,
          Objectnummer: "Popeye (KJA-Z001)",
          Locatie: "Leiden",
          Serienummer: 543112233,
          Tellerstand: "2313 uur (01-06-2021)",
          Contract: "31-12-2021",
          notification: 0,
          notficationID: 0,
        },
        {
          id: 4,
          Objectnummer: "Popeye (KJA-Z001)",
          Locatie: "Leiden",
          Serienummer: 543112233,
          Tellerstand: "2313 uur (01-06-2021)",
          Contract: "31-12-2021",
          notification: 11,
          notficationID: 1,
        },
        {
          id: 5,
          Objectnummer: "Popeye (KJA-Z001)",
          Locatie: "Leiden",
          Serienummer: 543112233,
          Tellerstand: "2313 uur (01-06-2021)",
          Contract: "31-12-2021",
          notification: 0,
          notficationID: 0,
        },
        {
          id: 6,
          Objectnummer: "Popeye (KJA-Z001)",
          Locatie: "Leiden",
          Serienummer: 543112233,
          Tellerstand: "2313 uur (01-06-2021)",
          Contract: "31-12-2021",
          notification: 5,
          notficationID: 1,
        },
        {
          id: 7,
          Objectnummer: "Popeye (KJA-Z001)",
          Locatie: "Leiden",
          Serienummer: 543112233,
          Tellerstand: "2313 uur (01-06-2021)",
          Contract: "31-12-2021",
          notification: 0,
          notficationID: 0,
        },
        {
          id: 8,
          Objectnummer: "Popeye (KJA-Z001)",
          Locatie: "Leiden",
          Serienummer: 543112233,
          Tellerstand: "2313 uur (01-06-2021)",
          Contract: "31-12-2021",
          notification: 9,
          notficationID: 1,
        },
        {
          id: 9,
          Objectnummer: "Popeye (KJA-Z001)",
          Locatie: "Leiden",
          Serienummer: 543112233,
          Tellerstand: "2313 uur (01-06-2021)",
          Contract: "31-12-2021",
          notification: 0,
          notficationID: 0,
        },
        {
          id: 10,
          Objectnummer: "Popeye (KJA-Z001)",
          Locatie: "Leiden5",
          Serienummer: 543112233,
          Tellerstand: "2313 uur (01-06-2021)",
          Contract: "31-12-2021",
          notification: 88,
          notficationID: 1,
        },
      ],
      thumbStyleMB: {
        right: "-18px",
        borderRadius: "5px",
        backgroundColor: "#a00020",
        width: "5px",
        opacity: 0.75,
      },
      barStyleMB: {
        right: "-20px",
        borderRadius: "9px",
        backgroundColor: "#a00020",
        width: "8px",
        opacity: 0.2,
      },
    };
  },
  methods: {
    UpdateEndObjectDate() {
      console.log("range dates", this.endDate);
      this.objectEndDate = this.endDate;
      this.$refs.endDateObject.hide();

      console.log("checking style", this.$refs.endDateObject);
    },

    rowDetails(data) {
      console.log("SELECTED_ROW", data);
      localStorage.setItem("fromPath", "row");
      localStorage.setItem("idObject", data.idObjects);
      localStorage.setItem("idContracts", data.idContracts);
      localStorage.setItem("idContactPerson", data.idContactPerson);
      localStorage.setItem("AccountName", data.AccountName);
      localStorage.setItem("SerialNumber", data.SerialNumber);
      localStorage.setItem("Status", data.Status);
      localStorage.setItem("ExternalReference", data.ExternalReference);
      this.$router.push({ name: "Machinedetails", query: { id: data.id } });
    },
    goHomePage() {
      this.$router.push({ name: "Home" });
    },
    warningInputValidation(val) {
      // if (val && val.length > 3) {
      //   this.warningInputPopup = true;
      // } else {
      //   this.warningInputPopup = false;
      // }
      if (Number(this.MachineHours) < Number(val)) {
        this.warningInputPopup = false;
      } else this.warningInputPopup = true;
    },
    CallServicePopup(data) {
      localStorage.setItem("uwReference", data.uwReference);
      localStorage.setItem("SerialNumber", data.SerialNumber);
      localStorage.setItem("idObjects", data.idObjects);
      localStorage.setItem("objectDropdown", "0");
      this.$store.commit("SET_CUATION_POPUP", true);
    },
    tellerstandPopup(rowData) {
      // localStorage.setItem("ExternalReference", rowData.ExternalReference);
      // localStorage.setItem("SerialNumber", rowData.SerialNumber);
      // localStorage.setItem("BMWTEndDate", rowData.BMWTEndDate);
      // localStorage.setItem("MachineHours", rowData.MachineHours);
      // localStorage.setItem("MachineHoursDate", rowData.MachineHoursDate);
      console.log(rowData);
      this.ExternalReference = rowData.ExternalReference;
      this.SerialNumber = rowData.SerialNumber;
      this.NotificationStatus = rowData.Status;
      this.MachineHours = rowData.MachineHours;
      this.MachineHoursDate = rowData.MachineHoursDate;
      this.idContactPerson = rowData.idContactPerson;
      this.idContracts = rowData.idContracts;
      this.editHour = rowData.editHours;
      this.nieuweUrenstand = rowData.nieuweUrenstand;
      this.tellerstandWijzigen = true;
      // this.tellerstand_mobile = true;
    },
    updateHours() {
      this.tellerstandWijzigen = this.editHour ? true : false;
      this.editHour = false;

      this.$store.dispatch("postTellerStandHours", {
        this: this,
        idContactPerson: this.idContactPerson ? this.idContactPerson : null,
        idContracts: this.idContracts ? this.idContracts : null,
        newHour: this.nieuweUrenstand,
      });
    },

    objectnummerWijzigenPopup(rowData) {
      this.NotificationStatus = rowData.Status;
      this.SerialNumber = rowData.SerialNumber;
      this.ExternalReference = rowData.ExternalReference;
      this.idContactPerson = rowData.idContactPerson;
      this.idContracts = rowData.idContracts;
      this.editObjNummer = rowData.editObjNummer;
      this.objNummer = rowData.objNummer;
      this.objectnummerDialog = true;
    },
    updateObjectNummer() {
      this.objectnummerDialog = this.editObjNummer ? true : false;
      this.editObjNummer = false;

      this.$store.dispatch("postObjectNumber", {
        this: this,
        idContactPerson: this.idContactPerson ? this.idContactPerson : null,
        idContracts: this.idContracts ? this.idContracts : null,
        objectNumber: this.objNummer,
      });
    },
    inactief(rowData) {
      this.uwReference = rowData.uwReference;
      this.NotificationStatus = rowData.Status;
      this.SerialNumber = rowData.SerialNumber;
      this.idContactPerson = rowData.idContactPerson;
      this.idContracts = rowData.idContracts;
      this.editRegion = rowData.editRegion;
      this.redenInput = rowData.redenInput;
      this.Request_EndDateObject = rowData.Request_EndDateObject;
      this.objectEndDate = null;
      this.inactiefPopup = true;
    },
    ObjectInactBtn() {
      this.$store.dispatch("postObjectInactive", {
        this: this,
        idContactPerson: this.idContactPerson ? this.idContactPerson : null,
        idContracts: this.idContracts ? this.idContracts : null,
        Request_Reason: this.objNummer,
        objectEnddate: this.objectEndDate,
      });

      this.inactiefPopup = this.editRegion ? true : false;
      this.editRegion = false;
    },
    gotoContract(rowData) {
      console.log("GO_TO_CONTRACT_PAGE");
      localStorage.setItem("idObject", rowData.idObjects);
      localStorage.setItem("idContracts", rowData.idContracts);
      localStorage.setItem("AccountName", rowData.AccountName);
      localStorage.setItem("SerialNumber", rowData.SerialNumber);

      localStorage.setItem("fromPath", "menu");
      this.$router.push({ name: "Machinedetails" });
    },
    getAccountUsers() {
      this.$store.dispatch("getAccountUsers", {
        this: this,
      });
    },
    getVlootData() {
      localStorage.setItem(
        "locateiId",
        this.Locatie ? this.Locatie.value : null
      );
      console.log("Locate", this.Locatie);
      this.$store.dispatch("getVlootData", {
        this: this,
        Locatie: this.Locatie ? this.Locatie.value : null,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.search-block {
  margin-top: -30px;
  z-index: 2000;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #00000029;
  border-radius: 4px 0px 0px 4px;
  opacity: 1;
  height: 50px;
  position: sticky;
}
.localite-seprator-div {
  border-right: 1px solid #898989;
}
.select-localite {
  width: 100%;
}
.categorie-seprator-div {
  border-right: 1px solid #898989;
}
.select-categorie {
  width: 100%;
}
.serch-input-div {
  display: flex;
}
.search-input {
  width: 100%;
}
.search-icon-div {
  background: #a00020;
  color: white;
  padding: 0px;
  width: 45px;
  margin-top: 0px;
  margin-left: 6px;
  text-align: center;
  cursor: pointer;
  padding-top: 10px;
  margin-bottom: 0px;
}
.table-notification {
  color: #a00020 !important;
  border: 1px solid #a00020;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  padding-top: 1px;
  box-shadow: 0px 0px 6px #951c2271;
}
.mign-text {
  padding-left: 18px;
  font: normal normal bold 25px/66px Arial;
  letter-spacing: 1.5px;
  color: #000000;
  text-transform: uppercase;
  padding-top: 15px;
}
.table-div {
  padding: 10px;
}
.back-button-div {
  position: absolute;
  margin-top: -179px;
  padding-left: 2px;
  z-index: 2000;
}
.back-button {
  background-color: #a00020 !important;
}
.back-button:hover {
  background-color: black !important;
}
.wijzigen-block-text {
  font: normal normal bold 17px/20px Arial;
  letter-spacing: 0.85px;
  color: #333434;
}
.objectnummer-block-text {
  font: normal normal bold 17px/20px Arial;
  letter-spacing: 0.85px;
  color: #333434;
}
.inactief-block-text {
  font: normal normal bold 17px/20px Arial;
  letter-spacing: 0.85px;
  color: #333434;
}
.in-validation {
  font: normal normal bold 17px/20px Arial;
  letter-spacing: 0.85px;
  color: #a00020;
}
.region-block-text {
  font: normal normal bold 17px/20px Arial;
  letter-spacing: 0.85px;
  color: #333434;
}
.region-block-text-red {
  font: normal normal bold 17px/20px Arial;
  letter-spacing: 0.85px;
  color: #a00020;
}
.opslaan-btn-div {
  text-align: center;
}
.opslaan-btn {
  background: #a00020 !important;
  letter-spacing: 0.75px;
}
.opslaan-btn:hover {
  background: #333434 !important;
  color: white;
}
.warning-input-val {
  color: #a00020;
}
.higlihtedStar {
  color: #a00020;
  font-size: 20px;
}
.redenHint {
  color: #a00020;
}
//Mobile devlopment
.zoekInputObject {
  padding-left: 10px;
}
.search-icone-Zoek {
  background: #a00020;
  padding-top: 10px;
  padding-bottom: 10px;
}
.ge-trug-vloot-div {
  width: 100%;
  text-align: center;
}
.mb-mijn-heading {
  font: normal normal bold 18px/25px Arial;
  letter-spacing: 1px;
  color: #333434;
  text-transform: uppercase;
}
.mb-notification-card {
  min-height: 60px;
}
.gray-color-notification {
  border-left: 15px solid #333434;
}
.red-color-notification {
  border-left: 15px solid #a00020;
}
.mb-notification-count {
  height: 27px;
  width: 27px;
  border-radius: 50% !important;
  display: inline-block !important;
  color: white;
  border: 2px solid #ffffff !important;
  margin-left: 4px !important;
  padding-top: 2px !important;
  margin-top: 18px !important;
  position: absolute;
  left: -38px !important;
  text-align: center;
}
.mb-card-details {
  display: flex;
  justify-content: space-between;
}
.mb-vloot-heading {
  display: flex;
}
.vloot-location {
  font: normal normal bold 11px/17px Arial;
  color: #a00020;
  text-transform: uppercase;
}
.vloot-popye-icone {
  margin-top: -4px;
}
.vloot-object {
  font: normal normal bold 11px/17px Arial;
  color: #000000;
  text-transform: uppercase;
}
.mb-serienummer {
  font: normal normal bold 11px/5px Arial;
  color: #333434;
}
.mb-tellerstand {
  font: normal normal bold 11px/5px Arial;
  color: #333434;
}
.mb-card-scrool {
  height: 500px;
  max-width: 100%;
}
.mb-card-icone {
  transform: translate(0, 30%);
}

.inactive-input-date-box {
  border: 1px solid;
  border-radius: 2em;
}
.inactive-input-date-box:hover {
  border: 2px solid #4e9ce1;
}

@media all and (max-width: 1023px) {
  .mobile-vloot-table {
    display: block;
  }
  .desktop-vloot-table {
    display: none;
  }
  .desktop-tellerstand-popup {
    display: none;
  }
  .mobile-tellerstand-popup {
    display: block;
  }
}
@media all and (min-width: 1024px) {
  .mobile-vloot-table {
    display: none;
  }
  .desktop-vloot-table {
    display: block;
    width: 90%;
    margin: auto;
  }
  .desktop-tellerstand-popup {
    display: block;
  }
  .mobile-tellerstand-popup {
    display: none;
  }
}
</style>
