<template>
  <div>
    <q-card-section>
      <!-- Desktop view -->
      <div class="desktop-service-popup">
        <div class="row object-popeye-div">
          <div class="col-lg-3 col-md-3 col-sm-0 col-xs-0">
            <div class="object-text">
              Truck
            </div>
          </div>
          <div class="col-lg-1 col-md-1"></div>
          <div class="col-lg-8 col-md-8 col-sm-0 col-xs-0">
            <q-select
              dense
              rounded
              outlined
              label="Selecteer object…"
              v-model="objectmodel"
              :options="
                objectDropdown === '1' ? objectdropdowndata : objectOptions
              "
            >
              <template v-slot:option="scope">
                <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                  <q-item-section avatar>
                    <q-icon :name="scope.opt.icon" />
                  </q-item-section>

                  <q-item-section>
                    <q-item-label v-html="scope.opt.label" />
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
        </div>
        <div class="row  foutcode-input-div">
          <div class="col-lg-3 col-md-3">
            <div class="foutcode-object-text">
              Opdrachtnummer
            </div>
          </div>
          <div class="col-lg-1 col-md-1"></div>
          <div class="col-lg-8 col-md-8">
            <q-input
              dense
              rounded
              outlined
              placeholder="Opdrachtnummer..."
              v-model="ReferentieModel"
            >
            </q-input>
          </div>
        </div>
        <div class="row  foutcode-input-div">
          <div class="col-lg-3 col-md-3">
            <div class="foutcode-object-text">
              Type melding
            </div>
          </div>
          <div class="col-lg-1 col-md-1"></div>
          <div class="col-lg-8 col-md-8">
            <q-select
              dense
              rounded
              outlined
              label="Selecteer type melding..."
              v-model="servicetypemodel"
              :options="servicetypedata"
            >
            </q-select>
          </div>
        </div>
        <div
          class="row  foutcode-input-div"
          v-if="servicetypemodel && servicetypemodel.label === 'Storing'"
        >
          <div class="col-lg-3 col-md-3">
            <div class="foutcode-object-text">
              Foutcode
            </div>
          </div>
          <div class="col-lg-1 col-md-1"></div>
          <div class="col-lg-8 col-md-8">
            <q-input
              dense
              rounded
              outlined
              placeholder="Foutcode…"
              v-model="foutCodeModel"
            >
            </q-input>
          </div>
        </div>

        <div
          class="row  foutcode-input-div"
          v-if="servicetypemodel && servicetypemodel.label === 'Onderhoud'"
        >
          <div class="col-lg-3 col-md-3">
            <div class="foutcode-object-text">
              Tellerstand
            </div>
          </div>
          <div class="col-lg-1 col-md-1"></div>
          <div class="col-lg-8 col-md-8">
            <q-input
              dense
              rounded
              outlined
              placeholder="Tellerstand…"
              v-model="tellerStandModel"
              type="number"
            >
            </q-input>
          </div>
        </div>

        <!-- <div class="q-py-lg"></div> -->
        <!-- <div class="q-py-lg"></div> -->
        <div class="omschrijving-text" style="padding-top:15px">
          Omschrijving
        </div>
        <div class="row q-pt-md">
          <div class="col-md-12 col-lg-12">
            <div class="q-mr-sm">
              <q-input
                type="textarea"
                rows="4"
                outlined
                v-model="omschrijvingInput"
                placeholder="Vermeld het probleem + waar rijdt de truck (locatie, expeditie, hal 3, etc)"
                style="border-radius: 10px;"
                :rules="[(val) => !!val || 'Field is required']"
              />
            </div>
          </div>
          <!-- <div class="col-md-6 col-lg-6">
           
          </div> -->
        </div>
        <div class="row" style="padding-top: 18px;">
          <!-- fileCount = $event.target.files.length; -->
          <div class="col">
            <form
              enctype="multipart/form-data"
              novalidate
              v-if="isInitial || isSaving"
            >
              <div class="dropbox">
                <input
                  type="file"
                  multiple
                  :name="uploadFieldName"
                  @change="
                    filesChange($event.target.name, $event.target.files);
                    fileCount = $event.target.files.length;
                  "
                  accept="image/*,video/*"
                  class="input-file"
                  :disabled="isSaving"
                />
                <div class="upload-file q-py-md" v-if="isInitial">
                  <div class="cloud-icone-div" align="center">
                    <img src="../assets/cloud-upload-icon.png" alt="" />
                  </div>
                  <div class="upload-text">
                    Foto's helpen ons om u sneller van dienst te zijn
                    <ul>
                      <li v-for="(item, index) in uploadedFiles" :key="index">
                        {{ item.FileName }}
                      </li>
                    </ul>
                  </div>
                </div>
                <p v-if="isSaving">Uploading {{ fileCount }} files...</p>
              </div>

              <!-- <div class="dropbox q-ml-sm">
                <input
                  type="file"
                  multiple
                  :name="uploadFieldName"
                  @change="
                    filesChange($event.target.name, $event.target.files);
                    fileCount = $event.target.files.length;
                  "
                  accept="image/*"
                  class="input-file"
                  :disabled="isSaving"
                />
                <div class="upload-file q-py-md" v-if="isInitial">
                  <div class="cloud-icone-div" align="center">
                    <q-icon name="cloud_upload" size="60px" />
                  </div>
                  <div class="upload-text">
                    Upload foto of video{{ uploadFieldName }}
                  </div>
                </div>
                <p v-if="isSaving">Uploading {{ fileCount }} files...</p>
              </div> -->
            </form>
            <div
              style="margin-top: 6px;
    color: #c10025;
    font-size: 13px;"
              v-if="isFileSizeMore"
            >
              upload het bestand minder dan 9 mb
            </div>
          </div>
        </div>
        <div class="q-py-lg"></div>
        <!-- <div class="q-py-lg"></div> -->
        <div class="aanmaken-div">
          <q-btn
            type="submit"
            class="aanmaken-button 
            text-white"
            label="Aanmaken"
            :disable="omschrijvingInput"
            @click="CreateService"
          />
        </div>
      </div>

      <!-- Mobile view -->
      <div class="mobile-service-popup">
        <div class="maaak-service-text q-py-lg">
          Maak servicemelding bij:
        </div>
        <div class="row q-pt-sm  q-pb-md">
          <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
          <div class="col-md-0 col-lg-0 col-sm-10 col-xs-10">
            <div class="futcode-text q-pb-sm">
              Truck
            </div>
            <div class="mb-select-object">
              <div class="row">
                <!-- <div class="col-md-0 col-lg-0 col-sm-6 col-xs-6">
                  <div class="mb-popeye-icone">
                    <div class="mb-popeye-text">
                      popeye
                    </div>
                    <div style="padding-top: 4px;">
                      <q-icon name="arrow_right" size="30px" />
                    </div>
                  </div>
                </div> -->
                <div class="col-md-0 col-lg-0 col-sm-12 col-xs-12">
                  <!-- <q-select
                    options-dense
                    dense
                    v-model="objectLeiden"
                    :options="popeyeLeiden"
                    borderless
                  /> -->
                  <q-select
                    dense
                    rounded
                    outlined
                    label="Selecteer object…"
                    v-model="objectmodel"
                    :options="
                      objectDropdown === '1'
                        ? objectdropdowndata
                        : objectOptions
                    "
                  >
                    <template v-slot:option="scope">
                      <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                        <q-item-section avatar>
                          <q-icon :name="scope.opt.icon" />
                        </q-item-section>

                        <q-item-section>
                          <q-item-label v-html="scope.opt.label" />
                        </q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
        </div>
        <div class="row q-py-sm">
          <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
          <div class="col-md-0 col-lg-0 col-sm-10 col-xs-10">
            <div class="futcode-text q-pb-sm">
              Opdrachtnummer
            </div>
            <q-input
              dense
              rounded
              outlined
              v-model="ReferentieModel"
              placeholder="Opdrachtnummer..."
            />
          </div>
          <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
        </div>
        <div class="row q-py-sm">
          <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
          <div class="col-md-0 col-lg-0 col-sm-10 col-xs-10">
            <div class="futcode-text q-pb-sm">
              Type melding
            </div>
            <q-select
              dense
              rounded
              outlined
              label="Selecteer type melding..."
              v-model="servicetypemodel"
              :options="servicetypedata"
            >
            </q-select>
          </div>
          <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
        </div>
        <div
          class="row q-py-sm"
          v-show="servicetypemodel && servicetypemodel.label === 'Storing'"
        >
          <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
          <div class="col-md-0 col-lg-0 col-sm-10 col-xs-10">
            <div class="futcode-text q-pb-sm">
              Foutcode object
            </div>
            <q-input
              dense
              rounded
              outlined
              v-model="foutCodeModel"
              placeholder="Foutcode object…"
            />
          </div>
          <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
        </div>
        <div
          class="row q-py-sm"
          v-show="servicetypemodel && servicetypemodel.label === 'Onderhoud'"
        >
          <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
          <div class="col-md-0 col-lg-0 col-sm-10 col-xs-10">
            <div class="futcode-text q-pb-sm">
              Tellerstand
            </div>
            <q-input
              dense
              rounded
              outlined
              placeholder="Tellerstand…"
              v-model="tellerStandModel"
              type="number"
            />
          </div>
          <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
        </div>

        <div class="row q-pt-sm  q-pb-md">
          <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
          <div class="col-md-0 col-lg-0 col-sm-10 col-xs-10">
            <div class="mb-omschrijving-text q-pb-sm">
              Omschrijving
            </div>
            <q-input
              type="textarea"
              rows="3"
              outlined
              v-model="omschrijvingInput"
              placeholder="Omschrijving bij servicemelding…"
            />
          </div>
          <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
        </div>
        <div class="row q-pt-sm  q-pb-md">
          <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
          <div class="col-md-0 col-lg-0 col-sm-10 col-xs-10">
            <form
              enctype="multipart/form-data"
              novalidate
              v-if="isInitial || isSaving"
            >
              <div class="dropbox">
                <input
                  type="file"
                  multiple
                  :name="uploadFieldName"
                  @change="
                    filesChange($event.target.name, $event.target.files);
                    fileCount = $event.target.files.length;
                  "
                  accept="image/*,video/*"
                  class="input-file"
                  :disabled="isSaving"
                />
                <div class="upload-file q-py-md" v-if="isInitial">
                  <div class="cloud-icone-div" align="center">
                    <img src="../assets/cloud-upload-icon.png" alt="" />
                  </div>
                  <div class="upload-text">
                    Foto's helpen ons om u sneller van dienst te zijn
                    <ul>
                      <li v-for="(item, index) in uploadedFiles" :key="index">
                        {{ item.FileName }}
                      </li>
                    </ul>
                  </div>
                </div>
                <p v-if="isSaving">Uploading {{ fileCount }} files...</p>
              </div>
            </form>
            <div
              style="margin-top: 6px;
    color: #c10025;
    font-size: 13px;"
              v-if="isFileSizeMore"
            >
              upload het bestand minder dan 9 mb
            </div>

            <!-- <form
              enctype="multipart/form-data"
              novalidate
              v-if="isInitial || isSaving"
            >
              <div class="mb-dropbox">
                <input
                  type="file"
                  multiple
                  :name="uploadFieldName"
                  :disabled="isSaving"
                  @change="
                    filesChange($event.target.name, $event.target.files);
                    fileCount = $event.target.files.length;
                  "
                  accept="image/*"
                  class="mb-input-file"
                />
                <div class="mb-upload-file" v-if="isInitial">
                  <div class="mb-cloud-icone-div" align="center">
                    <q-icon name="cloud_upload" size="50px" />                   
                  </div>
                  <div class="upload-text-mb">
                    Upload foto of video
                  </div>
                </div>
                //v-if="isSaving"
                <p>Uploading {{ fileCount }} files...</p>
              </div>filesChange
            </form> -->
          </div>
          <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
        </div>
        <div class="row q-py-sm">
          <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
          <div class="col-md-0 col-lg-0 col-sm-10 col-xs-10">
            <div class="mb-annamaken-button-div">
              <q-btn
                type="submit"
                class="mb-aanmaken-button text-white"
                label="Aanmaken"
                @click="CreateService"
              />
            </div>
          </div>
          <div class="col-md-0 col-lg-0 col-sm-1 col-xs-1"></div>
        </div>
        <div class="ga-terug q-py-lg">
          <q-btn
            icon-right="subdirectory_arrow_left"
            label="Ga terug"
            flat
            no-caps
            color="grey-8"
            @click="gaTerug"
          />
        </div>
      </div>
    </q-card-section>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
export default {
  created() {
    this.GetServiceType();
  },
  computed: {
    ...mapState({
      objectdropdowndata: "objectdropdowndata",
      servicetypedata: "servicetypedata",
    }),
    isInitial() {
      return this.currentStatus === "STATUS_INITIAL";
    },
    isSaving() {
      return this.currentStatus === "STATUS_SAVING";
    },
    isSuccess() {
      return this.currentStatus === "STATUS_SUCCESS";
    },
    isFailed() {
      return this.currentStatus === "STATUS_FAILED";
    },

    // if (this.objectDropdown == "0") {
    //   this.objectmodel.label = localStorage.getItem("uwReference");
    //   this.objectmodel.value = localStorage.getItem("idObjects");
    // }

    // ObjectDropDown() {
    //   const isDropDownTrue = localStorage.getItem("objectDropdown");
    //   console.log(typeof isDropDownTrue);
    //   console.log("isDropDownTrue", isDropDownTrue);
    //   console.log(localStorage.getItem("SerialNumber"));
    //   if (isDropDownTrue === "1") {
    //     this.objectOptions = this.objectdropdowndata;
    //   } else if (isDropDownTrue === "0") {
    //     const SerialNumber = localStorage.getItem("SerialNumber");
    //     const idObjects = localStorage.getItem("idObjects");
    //     console.log("idobj", idObjects);
    //     this.objectOptions = [
    //       {
    //         label: SerialNumber,
    //         value: idObjects,
    //       },
    //     ];
    //   }
    //   console.log("object Option", this.objectOptions);
    // },
  },
  data() {
    return {
      isFileSizeMore: false,
      tellerStandModel: null,
      foutCodeModel: null,
      ReferentieModel: "",
      servicetypemodel: null,
      objectDropdown: localStorage.getItem("objectDropdown"),
      objectmodel:
        localStorage.getItem("uwReference") !== ""
          ? {
              label: localStorage.getItem("uwReference"),
              value: localStorage.getItem("idObjects"),
            }
          : "",

      objectOptions: [
        {
          // label: localStorage.getItem("SerialNumber"),
          label: localStorage.getItem("uwReference"),
          value: localStorage.getItem("idObjects"),
        },
      ],

      fileCount: null,
      uploadedFiles: [],
      uploadError: null,
      currentStatus: "STATUS_INITIAL",
      uploadFieldName: "xlsx",
      uploadedFile: {
        FileName: "",
        FileData: "",
      },
      objectPopeye: null,
      popeyeOptions: ["Google", "Facebook", "Twitter", "Apple", "Oracle"],
      objectLeiden: null,
      popeyeLeiden: ["Google", "Facebook", "Twitter", "Apple", "Oracle"],
      foutcodeInput: "",
      omschrijvingInput: "",
      model: null,
      options: [
        {
          label: "Google",
          value: "Google",

          icon: "mail",
        },
        {
          label: "Facebook",
          value: "Facebook",

          icon: "bluetooth",
        },
        {
          label: "Twitter",
          value: "Twitter",

          icon: "map",
        },
        {
          label: "Apple",
          value: "Apple",

          icon: "golf_course",
        },
        {
          label: "Oracle",
          value: "Oracle",
          disable: true,

          icon: "casino",
        },
      ],
    };
  },
  methods: {
    save(formData) {
      // upload data to the server
      this.currentStatus = "STATUS_SAVING";

      upload(formData)
        .then((x) => {
          this.currentStatus = "STATUS_SUCCESS";
        })
        .catch((err) => {
          this.uploadError = err.response;
          this.currentStatus = "STATUS_FAILED";
        });
    },
    filesChange(fieldName, fileList) {
      console.log("fieldName", fieldName);
      console.log("fileList", fileList);
      // this.uploadedFiles = fileList;
      let t = this;

      function readFileAsText(file) {
        return new Promise(function(resolve, reject) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = function() {
            let base64data = reader.result;
            // console.log(base64data.split("base64,")[1]);
            //  const fileData= base64data.split("base64,")[1],
            resolve(base64data.split("base64,")[1]);
          };
        });
      }
      let readers = [];
      for (let i = 0; i < fileList.length; i++) {
        // readers.push(readFileAsText(fileList[i]));

        console.log("checking file size", fileList[i].size / 1024 ** 2);

        if (fileList[i].size / 1024 ** 2 >= 9) {
          this.isFileSizeMore = true;
        } else {
          this.isFileSizeMore = false;
          readFileAsText(fileList[i]).then((fileContent) => {
            // Print file content on the console
            let uploadedFile = {
              UploadedFile: fileContent,
              FileName: fileList[i].name,
            };
            t.uploadedFiles.push(uploadedFile);
          });
        }
      }

      // Promise.all(readers).then((values) => {
      //     // Values will be an array that contains an item
      //     // with the text of every selected file
      //     // ["File1 Content", "File2 Content" ... "FileN Content"]
      //     console.log(values);
      // });

      //  let t = this;
      //   let reader = new FileReader();

      // if (fileList.length>0) {
      //   for(let i=0;i<fileList.length;i++){
      //    reader.readAsDataURL(fileList[i]);

      //   reader.onloadend =  function() {
      //     let base64data = reader.result;
      //    let uploadedFile = {
      //       FileName: fileList[i].name,
      //       FileData: base64data.split("base64,")[1],
      //     };
      //    console.log('need to push img',uploadedFile);
      //     t.uploadedFiles.push(uploadedFile);
      //    console.log("uploadedFile", t.uploadedFile);
      //     t.$store.dispatch("fileUpload", {this:t})
      //   };
      //   }
      // }
      console.log("finalimg", this.uploadedFiles);
    },
    gaTerug() {
      console.log("gaTerug");
      this.ServiceMobilePopup = false;
      this.$store.commit("SET_SERVICE_MOBILE_POPUP", false);
    },
    CreateService() {
      console.log("CLOSE_ANAMAKEN_BUTTON");

      this.$store.dispatch("postCreateNewService", {
        this: this,
        idObject: this.objectmodel ? this.objectmodel.value : null,
        reffrentieNummer: this.ReferentieModel,
        typemelding: this.servicetypemodel ? this.servicetypemodel.value : null,
        foutCodeObject: this.foutCodeModel,
        description: this.omschrijvingInput,
        PostFilesdata: this.uploadedFiles,
        tellerStand: this.tellerStandModel,
      });

      this.$store.commit("SET_CUATION_POPUP", false);
      this.$store.commit("SET_SERVICE_MOBILE_POPUP", false);
    },

    GetServiceType() {
      this.$store.dispatch("GetServiceTypeData", {
        this: this,
      });
    },
  },
};
</script>
<style scoped>
.object-popeye-div {
  padding-top: 40px;
}
.object-text {
  font: normal normal bold 17px/20px Arial;
  letter-spacing: 0.85px;
  color: #333434;
}
.foutcode-input-div {
  padding-top: 30px;
}
.foutcode-object-text {
  font: normal normal bold 17px/20px Arial;
  letter-spacing: 0.85px;
  color: #333434;
}

.omschrijving-text {
  font: normal normal bold 17px/20px Arial;
  letter-spacing: 0.85px;
  color: #333434;
}
.dropbox {
  outline-offset: -10px;
  color: dimgray;
  position: relative;
  border: 2px dashed gray;
  /* border-radius: 30px 30px 30px 30px; */
  border-radius: 10px !important;
}
.input-file {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
}
.cloud-icone-div {
  padding-right: 30px;
}
.upload-text {
  font: normal normal normal 14px/16px Arial;
  letter-spacing: 0.7px;
  color: #333434;
}
.aanmaken-div {
  text-align: center;
}
.aanmaken-button {
  font-family: "Montserrat", sans-serif;
  background-color: #a00020 !important;
  width: 120px !important;
}
.aanmaken-button:hover {
  background-color: black !important;
  color: #a00020;
}
.upload-file {
  justify-content: center;
  display: flex;
}
.dropbox:hover {
  background: lightblue;
}
.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
.upload-text-mb {
  font: normal normal normal 14px/16px Arial;
  letter-spacing: 0.7px;
  color: #333434;
  padding-top: 25px;
}
.dropbox {
  outline-offset: -10px;
  color: dimgray;
  position: relative;
  border: 2px dashed gray;
  border-radius: 30px 30px 30px 30px;
}

/* mobile devlopment */
.maaak-service-text {
  text-align: center;
  font: normal normal bold 18px/25px Arial;
  letter-spacing: 1px;
  color: #333434;
  text-transform: uppercase;
}
.mb-select-object {
  border: 1px solid #dbdbdb;
  border-radius: 20px;
  height: 40px;
}
.futcode-text {
  font: normal normal bold 17px/20px Arial;
  letter-spacing: 0.85px;
  color: #333434;
}
.mb-omschrijving-text {
  font: normal normal bold 17px/20px Arial;
  letter-spacing: 0.85px;
  color: #333434;
}
.mb-annamaken-button-div {
  text-align: center;
}
.mb-dropbox {
  outline-offset: -10px;
  color: dimgray;
  /* min-height: 100px; */
  position: relative;
  border: 2px dashed gray;
  border-radius: 30px 30px 30px 30px;
}
.mb-input-file {
  opacity: 0;
  width: 100%;
  height: 90px;
  position: absolute;
  cursor: pointer;
}
.mb-upload-file {
  justify-content: center;
  padding: 20px;
  display: flex;
}
.mb-cloud-icone-div {
  padding-right: 30px;
}
.mb-aanmaken-button {
  font-family: "Montserrat", sans-serif;
  background-color: #a00020 !important;
}
.mb-aanmaken-button:hover {
  background-color: black !important;
  color: #a00020;
}
.ga-terug {
  text-align: center;
}
.mb-popeye-icone {
  display: flex;
  justify-content: center;
}
.mb-popeye-text {
  font: normal normal bold 14px/34px Arial;
  letter-spacing: 0.7px;
  color: #333434;
  text-transform: uppercase;
}
/* Media Query */
@media all and (min-width: 1651px) {
  .mobile-service-popup {
    display: none;
  }
}
@media all and (max-width: 1650px) and (min-width: 1551px) {
  .mobile-service-popup {
    display: none;
  }
}
@media all and (max-width: 1550px) and (min-width: 1371px) {
  .mobile-service-popup {
    display: none;
  }
}
@media all and (max-width: 1370px) and (min-width: 1301px) {
  .mobile-service-popup {
    display: none;
  }
}
@media all and (max-width: 1300px) and (min-width: 1201px) {
  .mobile-service-popup {
    display: none;
  }
}
@media all and (max-width: 1200px) and (min-width: 901px) {
  .mobile-service-popup {
    display: none;
  }
}

@media all and (max-width: 900px) and (min-width: 501px) {
  .desktop-service-popup {
    display: none;
  }
}
@media all and (max-width: 500px) and (min-width: 351px) {
  .desktop-service-popup {
    display: none;
  }
}
@media all and (max-width: 350px) and (min-width: 100px) {
  .desktop-service-popup {
    display: none;
  }
}
</style>
