import { render, staticRenderFns } from "./Activiteit.vue?vue&type=template&id=b9fb00a8&scoped=true&"
import script from "./Activiteit.vue?vue&type=script&lang=js&"
export * from "./Activiteit.vue?vue&type=script&lang=js&"
import style0 from "./Activiteit.vue?vue&type=style&index=0&id=b9fb00a8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9fb00a8",
  null
  
)

export default component.exports
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSelect,QInput,QIcon,QScrollArea,QCard,QBtn});
